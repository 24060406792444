.collapsible-section {
  margin-bottom: 1em;
}

.collapsible-title {
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  color: #d69b29;
  padding: 0.5em;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
  border-bottom: 1px solid #dbe7ff; /* Adds the grey line under the title */
}

.collapsible-title-close {
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  color: #112348;
  padding: 0.5em;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
  border-bottom: 1px solid #dbe7ff; /* Adds the grey line under the title */
}

.collapsible-content {
  padding: 0.5em;
  border: none;
}

.registration-firstPart {
  text-align: center;
  line-height: normal;
}

.registration-firstPart h3 {
  font-size: 5vw;
  color: #112348;
  font-weight: 600;
  letter-spacing: normal;
}

.registration-firstPart p {
  font-size: 3vw;
  color: #112348;
  font-weight: 400;
  width: 100%;
  margin: auto;
  letter-spacing: normal;
}

@media only screen and (min-width: 768px) {
  .registration-firstPart p {
    font-size: 18px;
    width: 70%;
  }
  .registration-firstPart h3 {
    font-size: 30px;
  }
  .registration-firstPart button {
    border-radius: 5px;

    padding: 8px 10px;
    font-size: 2vw;
  }
  .registration-firstPart br {
    margin: unset;
    display: unset;
    font-size: unset;
  }
}

.registration-firstPart br {
  content: "";
  margin: 2em;
  display: block;
  font-size: 24%;
}

.registration-firstPart button {
  border-radius: 5px;

  background-color: #d69b29;
  padding: 12px 16px;
  font-size: 16px;

  width: fit-content;
  color: white;
}

.includes-list {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.includes-list li {
  margin-bottom: 5px;
}

.list-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates 3 columns */
  grid-auto-rows: minmax(20px, auto); /* Creates rows with a minimum height */
  grid-gap: 16px; /* Space between grid items */
  padding: 0;
  color: #112348;
  list-style-type: disc; /* Standard bullet points */
  margin: 12px; /* Adjust as needed */
}

.list-grid li {
  letter-spacing: normal;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
}

.button {
  border-radius: 5px;
  background-color: #d69b29;
  padding: 12px 16px;
  font-size: 16px;
  width: fit-content;
  color: white;
}

.images-btn-Container {
  display: flex;
  margin-bottom: 1em;
  margin-top: 3em;
}

.images-btn-Container button {
  background-color: transparent;
  color: #112348;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #112348;
  margin-right: 0;
}

.image-btn-Clicked {
  color: #d69b29 !important;
  border-bottom: 1px solid #d69b29 !important;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}

*:focus {
  outline: none;
}

input {
  border: none;
  border-bottom: 1px solid #d69b29;
  width: 80vw;
  padding: 12px 0px;
  margin-bottom: 1em;
}

form select {
  border: none;
  border-bottom: 1px solid #d69b29;
  width: 80vw;
  padding: 12px 0px;
  margin-bottom: 1em;
  border-radius: 0;
  background-color: transparent;
  margin-top: 1em;
}

.contact-bottomTxt {
  font-size: 14px;
  letter-spacing: normal;
  color: #112348;
  font-weight: 400;
  width: 80vw;
  text-align: center;
  margin: 3em auto 1em auto;
}
::placeholder {
  color: #d69b29;
  opacity: 1; /* Firefox */
}

.partners-main {
  display: flex;
  flex-direction: column;
  padding-top: 64px;
}

.partners-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partners-span {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.partners-section h3 {
  font-size: 24px;
  color: #112348;
  font-weight: 600;
  letter-spacing: normal;
}

.partners-section span {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1100px) {
  .hide-mobile {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .hide-mobile-two {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .partners-section {
    align-items: start;
  }

  form select {
    width: 40vw;
    margin-bottom: 2em;
    border-radius: 0;
    background-color: transparent;
    margin-top: 1em;
  }

  input {
    width: 40vw;
    margin-bottom: 2em;
  }

  .contact-bottomTxt {
    width: 50vw;
  }
}

.image-container {
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}

/* Assuming these class names based on className props in the Modal */
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: white;
  overflow: auto;
  border-radius: 8px;
  outline: none;
  padding: 36px;
  width: 60%;
}

.Modal h2 {
  font-size: 30px;
  font-weight: 600;
  color: #112348;
}

.Modal button {
  width: fit-content;
  padding: 8px 16px;
  margin: auto;
}

.width-dynamic{
  width: 45%;
  margin: auto;
}


.Modal button {
  width: 200px;
  padding: 8px 16px;
  margin: auto;
}

@media only screen and (max-width: 774px) {
  .Modal {
    width: 90%;
  }
  .Modal h2 {
    font-size: 24px;
  }

  .width-dynamic{
    width: 70%;
    margin: auto;
  }

}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.dropdown {
  margin: 20px 0;
}

button {
  margin-right: 10px;
  padding: 10px 20px;
}
