



/* #PAGE LOAD */
.row-sb{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 50px auto;
	width: 60%;
}

.slider {
	width: 100%; /* Adjust width as needed */
	overflow: hidden; /* Hide overflowing content */
  }
  
  .item {
	display: inline-block; /* Display items side-by-side */
	width: 100%; /* Each item takes up full width of slider */
	transition: transform 0.5s ease-in-out; /* Smooth transition */
  }
  
  .item.active {
	transform: translateX(0); /* Slide into view */
  }
  
  .slider .item { /* Target all items within the slider */
	transform: translateX(-100%); /* Start off-screen to the left */
  }
  
.row-sb-content{
	width: 200px;
}

.align-text-center{
	text-align: center !important;
}

body.royal_loader {
    background: none;
    visibility: hidden;
}
#royal_preloader {
    font-family: Arial;
    font-size: 12px;
    visibility: visible;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 9999999999;
    -webkit-transition: opacity 0.2s linear 0.8s;
       -moz-transition: opacity 0.2s linear 0.8s;
        -ms-transition: opacity 0.2s linear 0.8s;
         -o-transition: opacity 0.2s linear 0.8s;
            transition: opacity 0.2s linear 0.8s;
}
#royal_preloader.complete {
    opacity: 0;
}
#royal_preloader .background {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #000000;
    -webkit-transition: background 0.5s linear;
       -moz-transition: background 0.5s linear;
        -ms-transition: background 0.5s linear;
         -o-transition: background 0.5s linear;
            transition: background 0.5s linear;
}
#royal_preloader.number .name {
    position: absolute;
    top: 20%;
    left: 0px;
    right: 0px;
    text-transform: uppercase;
    color: #EEEEEE;
    text-align: center;
    opacity: 0;
    -webkit-transition: -webkit-transform 0.3s linear;
       -moz-transition: -moz-transform 0.3s linear;
        -ms-transition: -ms-transform 0.3s linear;
         -o-transition: -o-transform 0.3s linear;
            transition: transform 0.3s linear;
    -webkit-animation: fly 0.3s;
       -moz-animation: fly 0.3s;
        -ms-animation: fly 0.3s;
         -o-animation: fly 0.3s;
            animation: fly 0.3s;
}
#royal_preloader.number .percentage {
    color: #FFFFFF;
    font-family: Impact, Arial;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin: -90px 0px 0px -90px;
     background-image: url('../images/logo.png'); 
    background-repeat: no-repeat;
    background-position: 50% 0;
    padding-top: 80px;
    width: 180px;
    position: absolute;
    font-size: 50px;
    top: 50%;
    left: 50%;
    -webkit-transition: all 0.3s linear 0.5s;
       -moz-transition: all 0.3s linear 0.5s;
        -ms-transition: all 0.3s linear 0.5s;
         -o-transition: all 0.3s linear 0.5s;
            transition: all 0.3s linear 0.5s;
}
#royal_preloader.text .loader {
    position: absolute;
	font-family: 'Lato', sans-serif;
	text-shadow: 0 0 15px rgba(0,0,0,0.3);
	font-weight:700;
	letter-spacing:10px;
	text-transform:uppercase;
	text-align:center;
    top: 50%;
    left: 50%;
	width:240px;
	margin-left:-120px;
    height: 60px;
    line-height: 60px;
    margin-top: -30px;
    font-size: 30px;
    white-space: nowrap;
}
#royal_preloader.text .loader div {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0%;
    background-color: #000000;
    opacity: 0.7;
    filter: alpha(opacity=70);
    -webkit-transition: left 0.3s linear;
       -moz-transition: left 0.3s linear;
        -ms-transition: left 0.3s linear;
         -o-transition: left 0.3s linear;
            transition: left 0.3s linear;
}
#royal_preloader.logo .loader {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -40px 0px 0px -40px;
    overflow: hidden;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
}
#royal_preloader.logo .loader div {
    position: absolute;
    bottom: 0%;
    left: 0px;
    right: 0px;
    height: 100%;
    opacity: 0.7;
    filter: alpha(opacity=70);
    -webkit-transition: bottom 0.3s linear;
       -moz-transition: bottom 0.3s linear;
        -ms-transition: bottom 0.3s linear;
         -o-transition: bottom 0.3s linear;
            transition: bottom 0.3s linear;
}
#royal_preloader.logo .percentage {
    position: absolute;
    width: 80px;
    height: 40px;
    line-height: 40px;
    top: 50%;
    left: 50%;
    margin: 40px 0px 0px -40px;
    color: #072E77;
    text-align: center;
    font-family: Arial;
    font-size: 12px;
    font-weight: bold;
}

@-webkit-keyframes fly {
    0% { opacity: 0; top: 50%; }
    20% {opacity: 1; top: 40%; }
    100% { opacity: 0; top: 20%;}
}
@-moz-keyframes fly {
    0% { opacity: 0; top: 50%; }
    20% {opacity: 1; top: 40%; }
    100% { opacity: 0; top: 20%;}
}
@-ms-keyframes fly {
    0% { opacity: 0; top: 50%; }
    20% {opacity: 1; top: 40%; }
    100% { opacity: 0; top: 20%;}
}
@-o-keyframes fly {
    0% { opacity: 0; top: 50%; }
    20% {opacity: 1; top: 40%; }
    100% { opacity: 0; top: 20%;}
}
@keyframes fly {
    0% { opacity: 0; top: 50%; }
    20% {opacity: 1; top: 40%; }
    100% { opacity: 0; top: 20%;}
}




/* #Menu
================================================== */

 
 
.header{
    position: fixed;
	width:100%;
	top:0;
	left:0;
	z-index:100;
}
 
.logo-wrap {
	display:block;
	float:left;
}
.logo-wrap img{
	width:110px;
	height:auto;
	display:block;
}


body.overflow-hidden {
  /* when primary navigation is visible, the content in the background won't scroll */
  overflow: hidden;
}
.cd-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 768px;
  margin: 0 auto;
}
.cd-container:after {
  content: "";
  display: table;
  clear: both;
}

/* -------------------------------- 

Main components 

-------------------------------- */


.cd-header {
  position: absolute;
  padding-top:5px;
  top: 0;
  left: 0;
  background: rgba(247, 247, 247, 0.95);
  height: 50px;
  width: 100%;
  z-index: 300;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media only screen and (min-width: 768px) {
  .cd-header {
    height: 80px;
    background: transparent;
    box-shadow: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1320px) {
.cd-header .columns {
	margin-top:25px;
	margin-bottom: 25px;
}
}
@media only screen and (min-width: 1170px) {
  .cd-header {
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    transition: background-color 0.3s;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .cd-header.is-fixed {
    /* when the user scrolls down, we hide the header right above the viewport */
    position: fixed;
    top: -80px;
    background-color: rgba(247, 247, 247, 0.96);
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
  }
  .cd-header.is-visible {
    /* if the user changes the scrolling direction, we show the header */
	padding-top:5px;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  .cd-header.menu-is-open {
    /* add a background color to the header when the navigation is open */
    background-color: rgba(247, 247, 247, 0);
  }
}


@media only screen and (min-width: 768px) {
  .cd-secondary-nav {
    display: block;
  }
}

.cd-primary-nav-trigger {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
}
.cd-primary-nav-trigger .cd-menu-text {
  color: #212121;
  text-transform: uppercase;
  font-weight: 700;
  /* hide the text on small devices */
  display: none;
}
.cd-primary-nav-trigger .cd-menu-icon {
  /* this span is the central line of the menu icon */
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top:-2px;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 22px;
  height: 2px;
  background-color: #212121;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  transition: background-color 0.3s;
  /* these are the upper and lower lines in the menu icon */
}
.cd-primary-nav-trigger .cd-menu-icon::before, .cd-primary-nav-trigger .cd-menu-icon:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color:  #212121;
  right: 0;
  -webkit-transition: -webkit-transform .3s, top .3s, background-color 0s;
  -moz-transition: -moz-transform .3s, top .3s, background-color 0s;
  transition: transform .3s, top .3s, background-color 0s;
}
.cd-primary-nav-trigger .cd-menu-icon::before {
  top: -7px;
}
.cd-primary-nav-trigger .cd-menu-icon::after {
  top: 7px;
}
.cd-primary-nav-trigger .cd-menu-icon.is-clicked {
  background-color: rgba(255, 255, 255, 0);
}
.cd-primary-nav-trigger .cd-menu-icon.is-clicked::before, .cd-primary-nav-trigger .cd-menu-icon.is-clicked::after {
  background-color:  #212121;
}
.cd-primary-nav-trigger .cd-menu-icon.is-clicked::before {
  top: 0;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.cd-primary-nav-trigger .cd-menu-icon.is-clicked::after {
  top: 0;
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
}
@media only screen and (min-width: 768px) {
  .cd-primary-nav-trigger {
    width: 100px;
    padding-left: 1em;
    background-color: transparent;
    height: 30px;
    line-height: 30px;
    right: 2.2em;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .cd-primary-nav-trigger .cd-menu-text {
    display: inline-block;
  }
  .cd-primary-nav-trigger .cd-menu-icon {
    left: auto;
    right: 1em;
    -webkit-transform: translateX(0) translateY(-50%);
    -moz-transform: translateX(0) translateY(-50%);
    -ms-transform: translateX(0) translateY(-50%);
    -o-transform: translateX(0) translateY(-50%);
    transform: translateX(0) translateY(-50%);
  }
}

.cd-primary-nav {
  /* by default it's hidden - on top of the viewport */
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(247, 247, 247, 0.96);
  z-index: 200;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: auto;
  /* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  transition-duration: 0.4s;
}
.cd-primary-nav ul.cd-scndr-nav{
	position:absolute;
	top: 50%;
	width:100%;
	max-width:300px;
	left:50%;
	margin-left:-150px;
	-webkit-transform: translateY(-55%);
	-moz-transform: translateY(-55%);
	-ms-transform: translateY(-55%);
	-o-transform: translateY(-55%);
	transform: translateY(-55%);
}
@media only screen and (max-width: 300px) {
.cd-primary-nav ul.cd-scndr-nav{
	left:0;
	margin-left:0;
}
}

.cd-primary-nav li {
  font-size: 11px;
  font-weight: 700;
  letter-spacing:2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.cd-primary-nav a {
  display: inline-block;
    text-transform: uppercase;
  padding: .42em 0.6em;
  color:#212121;
  -webkit-transition: background 0.3s, color 0.3s;
  -moz-transition: background 0.3s, color 0.3s;
  transition: background 0.3s, color 0.3s;
}
.no-touch .cd-primary-nav a:hover {
}
.cd-primary-nav .cd-label {
  color: #212121;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 30px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.cd-primary-nav.is-visible {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
@media only screen and (min-width: 1170px) {
  .cd-primary-nav li {
    font-size: 11px;
  }
}


.cd-main-content {
  position: relative;
  z-index: 1;
}
.cd-label ul{
	overflow: hidden;
	max-height: 0;
	transition:all 0.7s ease 0s;
	-moz-transition:all 0.7s ease 0s;
	-webkit-transition:all 0.7s ease 0s;
	-o-transition:all 0.7s ease 0s;
}
.cd-label:hover ul{
	margin-top:5px;
	margin-bottom:10px;
	max-height: 500px;
}
.cd-label ul a {
  padding: .4em .7em;
}

.social-nav{ 
	position:absolute;
	bottom:40px;
	width:100%;
	left:0;
	z-index:100;
	text-align:center;
	margin:0 auto;
	display:block;
}
.list-social-nav li {
    padding:0; 
    list-style: none;
	text-align:center; 
	width:50px;
	height:30px;
    display: inline-block;
	border-right:1px solid #bdbdbd;
	cursor:pointer;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.list-social-nav li:last-child {
	border-right:1px solid transparent;
}
.list-social-nav li.icon-soc-nav a {
	font-family: 'Poppins';
	font-size: 14px;
	line-height: 30px;
	width:50px;
	color:#000;
	opacity:1;
	margin:0;
	padding:0;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.list-social-nav li:hover{
	background:transparent;
}
.list-social-nav li:hover.icon-soc-nav a{
	background:transparent;
}



/* #Classic Navigation
================================================== */

.imgj {
	width: 100%;
	height: 100vh;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	left: 0;
	
	object-fit: cover;
	background-image: url("https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/backgrounds/First_Fold_Final.webp");
	z-index: -2;
	/* filter: grayscale(50%); Adjust the percentage for the desired level of grayscale */
  }
  .dashboard-sponsors{
	background-color: #112348;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 5em 1em;
	box-sizing: content-box;
	/* height: 20em; */
  }

.main-button {
	background-color: #D69B29;
	color: white;
	font-size: 14px;
	font-weight: 600;
	font-family: "Poppins";
	display: inline-block;
    overflow: hidden;
    white-space: nowrap;
	border-radius: 4px;
	padding: 8px 18px;
	gap: 8px;
	margin: 0 104px;
	cursor: pointer;
}
  
.register-button {
	background-color: #D69B29;
	color: white;
	font-size: 14px;
	font-weight: 600;
	font-family: "Poppins";
	border-radius: 4px;
	width: 200px;
	padding: 8px 0;
	margin-top: 14px;
	cursor: pointer;
}

#menu-wrap{
    position: fixed;
	padding-bottom:10px;
	display: flex;
	justify-content: space-between;
	width:100%;
	z-index:500;
	top:0;
	left:0;
}
#menu-wrap .container .columns  { 
	margin-top: 10px;
	margin-bottom: 10px;
}
.menu-back{
	background: transparent;
	-webkit-transition : all 0.3s ease-out;
	-moz-transition : all 0.3s ease-out;
	-o-transition :all 0.3s ease-out;
	transition : all 0.3s ease-out;
}

.top-flair{
	width: 100%;
	background-color: #D69B29;
	display: flex;
	justify-content: center;
	z-index: 1000;
	position: relative;
}

.flair-btn{
border: 1px solid white;
background-color: #D69B29;
color: white;
border-radius: 5px;
height: 20px;
width: fit-content;
padding: 3px 7px;
font-size: 11px;
margin: 0;
margin-right: 2em;
margin-left: 2em;
min-width: 100;
}



.flair-cont {
    width: 100%;
    height: 40px;
    background-color: transparent;
    color: white;
    white-space: nowrap;
    position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sliding-text {
    display: inline-block;
    text-align: center;
	width: 90%;
    position: absolute;
    white-space: nowrap;
    animation: slide-left 10s linear infinite;
}

@keyframes slide-left {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@media only screen and (max-width: 1000px) {
	.flair-cont {
		height: unset;
	}

	.flair-btn{
		border: 1px solid white;
		background-color: #D69B29;
		color: white;
		border-radius: 5px;
		height: 35px;
		width: fit-content;
		padding: 8px 12px;
		font-size: 16px;
		margin: 0;
		min-width: 100;
		}

		
	
.flair-content{
	width: fit-content;
	white-space: pre-wrap;
	text-align: center;
	margin-bottom: 1em;
}

.flair-cont {
    width: 100%;
    background-color: transparent;
    color: white;
    position: relative;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: center;
	padding: 10px 16px;
}
}

/* @media only screen and (max-width: 450px) {
	.top-flair{
		width: 120%;
	}
	.flair-cont {
		width: 120%;
	}
}

@media only screen and (min-width: 450px) and (max-width: 1000){
	.top-flair{
		width: 100%;
	}
	.flair-cont {
		width: 90%;
	}
} */


.cbp-af-header {
	-webkit-transition : all 0.3s ease-out;
	-moz-transition : all 0.3s ease-out;
	-o-transition :all 0.3s ease-out;
	transition : all 0.3s ease-out;
}

.blue-background{
	background-color: #112348;
}

.cbp-af-header.cbp-af-header-shrink {
	background: #112348;
	-webkit-transition : all 0.3s ease-out;
	-moz-transition : all 0.3s ease-out;
	-o-transition :all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.cbp-af-header.cbp-af-header-shrink .logo{
	width:150px;
	height:58px;
	top:10px;
	background:url('../images/mainlogo.svg') no-repeat center center;
	background-size:120px 58px;
	-webkit-transition : all 0.3s ease-out;
	-moz-transition : all 0.3s ease-out;
	-o-transition :all 0.3s ease-out;
	transition : all 0.3s ease-out;
}

.cbp-af-header.cbp-af-header-shrink ul.slimmenu {
	margin-top:1px;
	margin-bottom:0;
	-webkit-transition : all 0.3s ease-out;
	-moz-transition : all 0.3s ease-out;
	-o-transition :all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
.cbp-af-header.cbp-af-header-shrink ul.slimmenu li:first-child {
	background:none;
}
.cbp-af-header.cbp-af-header-shrink ul.slimmenu li ul li:first-child { 
	background:none; 
}
ul.slimmenu li ul li a {
    color: #000;
}
ul.slimmenu li ul li a:hover {
}
ul.slimmenu li ul li { 
	background:none;
	padding-left:0; }
	
ul.slimmenu li ul {
	background:rgba(255,255,255,.95);
	box-shadow: 0 0 15px rgba(0,0,0,0.2);
}



.container-top{
	/* padding: 0 84px; */
	width: 70vw;
}

.container-top-main{
	font-size: 24px !important;
	/* text-shadow: 0.1px 0.1px 0 #fff, -0.1px -0.1px 0 #fff, -0.1px 0.1px 0 #fff, 0.1px -0.1px 0 #fff, 
	0.1px 0 0 #fff, -0.1px 0 0 #fff, 0 0.1px 0 #fff, 0 -0.1px 0 #fff; */
	 font-weight:bolder; color: white; 
	 /* overflow: auto; white-space: pre-wrap; */
	 /* width: 80vw; */
	 text-align: center;
}

.container-top-subtitle{
	font-size: 16px; color: #ffffff; font-weight: 500;
	width: 80vw;
	
}


@media only screen and (max-width: 400px) {
	.smaller{
		font-size: 12px !important;
	}
}


@media only screen and (min-width: 500px) and (max-width: 1000px) {
	.container-top-main{
		font-size: 32px !important;
		text-align: center;

	}
	.container-top-subtitle{
		font-size: 14px !important;
		width: 45vw;
	}
}

	@media only screen and (min-width: 1000px) and (max-width: 1600px) {
		.container-top-main{
			font-size: 42px !important;
			text-align: center;
		}
		.container-top-subtitle{
			font-size: 16px;
			width: 42vw ;
		}
	}

.logo{
	width:150px;
	height:58px;
	cursor: pointer;
	z-index:10000;
	background:url('../images/mainlogo.svg') no-repeat center center;
	background-size:150px 58px;
	-webkit-transition : all 0.3s ease-out;
	-moz-transition : all 0.3s ease-out;
	-o-transition :all 0.3s ease-out;
	transition : all 0.3s ease-out;
	margin-right: 32px;
}

.logo-footer{
	background:url('../images/logofull.png') no-repeat center center;
	width:300px;
	height:71px;
	cursor: pointer;
	z-index:10000;
	background-size:300px 71px;
	-webkit-transition : all 0.3s ease-out;
	-moz-transition : all 0.3s ease-out;
	-o-transition :all 0.3s ease-out;
	transition : all 0.3s ease-out;

}

.appbar-left{
	display: flex;
	width: 900px;
	padding: 0 64px;
	/* justify-content: space-between; */
	align-items: center;
}



.menu-collapser {
    position: relative;
    width: 100%;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    padding: 0 8px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
	-webkit-transition : all 0.3s ease-out;
	-moz-transition : all 0.3s ease-out;
	-o-transition :all 0.3s ease-out;
	transition : all 0.3s ease-out;
}

.collapse-button {
    position: absolute;
    right: 8px;
    top: 60%;
    width: 40px;
    background-color: transparent;
    background-repeat: repeat-x;
    border-style: solid;
    color: #ffffff;
    padding: 7px 10px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;

    transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box
}
.collapse-button .icon-bar {
    background-color: #fff;
    border-radius: 1px 1px 1px 1px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    display: block;
    height: 2px;
    width: 18px;
    margin: 2px 0;
}

ul.slimmenu {
	padding-top:10px;
	padding-right:10px;
	text-align:right;
	-webkit-transition : all 0.3s ease-out;
	-moz-transition : all 0.3s ease-out;
	-o-transition :all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
ul.slimmenu li {
    position: relative;
    display: inline-block;
	background-size:15px 10px;
	padding-left:20px;
	-webkit-transition : all 0.3s ease-out;
	-moz-transition : all 0.3s ease-out;
	-o-transition :all 0.3s ease-out;
	transition : all 0.3s ease-out;
}
ul.slimmenu > li {}
ul.slimmenu > li:first-child { border-left: 0 }
ul.slimmenu > li:last-child { margin-right: 0 }
ul.slimmenu li a {
    display: block;
    color: #FFFFFF;
    padding: 10px 0px;
	cursor:pointer;
    margin: 0px 4px;
	font-family: 'Poppins';
	text-decoration: none !important;
	font-weight: 700;
	letter-spacing:1px;
    font-size: 14px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
ul.slimmenu li a:hover {
    text-decoration: none;
}
ul.slimmenu li {

}
ul.slimmenu li:first-child {
	background:none;
}

ul.slimmenu li .sub-collapser {
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    height: 100%;
    z-index: 999;
    cursor: pointer;
}
ul.slimmenu li .sub-collapser:before {
	display:none;
}
ul.slimmenu li .sub-collapser > i {
	display:none;
}
ul.slimmenu li ul {
    margin: 0;
    list-style-type: none;
}
ul.slimmenu li ul a{
    font-size:10px;
}
ul.slimmenu li ul li:first-child { 
	background:none; }
ul.slimmenu li ul li {
	background:none;
	padding-left:0; }
ul.slimmenu li > ul {
    display: none;
    position: absolute;
	text-align:left;
    left: 0;
    top: 100%;
    z-index: 999;
    width: 140%;
}
ul.slimmenu li > ul > li ul {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 999;
    width: 100%;
}
ul.slimmenu.collapsed {
	padding:0;
	margin:0;
}
ul.slimmenu.collapsed li {
    display: block;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
ul.slimmenu.collapsed li a {
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box
}
ul.slimmenu.collapsed li .sub-collapser {
    height: 40px;
}
ul.slimmenu.collapsed li > ul {
    display: none;
    position: static;
}




/* #Tooltip
================================================== */

	.tipper { height: 1px; left: -99999px; position: absolute; pointer-events: none; top: -99999px; width: 1px;z-index:100000; }
	.tipper .tipper-content { background: rgba(0, 0, 0, 0.85);box-shadow: 0 0 25px rgba(0,0,0,0.5);text-transform:uppercase; border-radius: 2px; color: #fff; font-weight:400; display: block; float: left; font-family: 'Poppins'; font-size: 10px; margin: 0; letter-spacing:2px; padding: 4px 20px; position: relative; white-space: nowrap; }
	.tipper .tipper-caret { background: url('../images/tipper-icon.png') no-repeat; display: block; height: 11px;  position: absolute; width: 100%; }

	.tipper.right .tipper-content { box-shadow: 1px 0 3px rgba(0, 0, 0, 0.25); }
	.tipper.right .tipper-caret { background-position: left center; left: -5px; top: 0; }

	.tipper.left .tipper-content { box-shadow: -1px 0 3px rgba(0, 0, 0, 0.25); }
	.tipper.left .tipper-caret { background-position: right center; right: -5px; top: 0; }

	.tipper.top .tipper-caret,
	.tipper.bottom .tipper-caret { display: block; float: none; height: 5px;  width: 12px;margin-left:-6px; }

	.tipper.top .tipper-content { box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.25); }
	.tipper.top .tipper-caret { background-position: center bottom; bottom: -5px; left: 0; text-align:center;}

	.tipper.bottom .tipper-content { box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25); }
	.tipper.bottom .tipper-caret { background-position: center top; top: -5px; left: 0; }



 .contactus-container{
	display: flex;
	flex-direction: column;
	padding: 64px;
	justify-content: center;
	align-items: center;
 }

/* #Hepo Slider 
================================================== */

#owl-top {
	position:relative;
	width:100%;
	margin:0 auto;
	overflow:hidden;
	height: 100vh;
}
#owl-top .item{
	position:relative;
	width:100%;
	height: 100vh;
	margin:0 auto;
	background-size:cover;
	background-position:center center;
	background-repeat:no-repeat;
	z-index:2;
	overflow:hidden;
}
.fullwidth-height #owl-top {
	height: 76vh;
}
.fullwidth-height #owl-top .item{
	height: 76vh;
}
#bar{
	width: 0%;
	max-width: 100%;
	height: 2px;
}
#progressBar{
	position:absolute;
	bottom:0;
	left:0;
	width: 100%;
	background: #000;
	z-index:5;
}
.prev{
	position:absolute;
	left:30px;
	top:50%;
	margin-top:-10px;
	width:50px;
	height:20px;
	z-index:20;
	cursor:pointer;
	background:url('../images/arrow-left.png')no-repeat center left;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.next{
	position:absolute;
	right:30px;
	top:50%;
	margin-top:-10px;
	width:50px;
	height:20px;
	z-index:20;
	cursor:pointer;
	background:url('../images/arrow-right.png')no-repeat center right;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.prev:hover,
.next:hover{
	width:70px;
}
.prev:hover{
	left:35px;
}
.next:hover{
	right:35px;
}
.white-over{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:rgba(255,255,255,.8);
	z-index:1;
}
 
.hero-top{
	position:absolute;
	left:0;
	top:40%;
	width:100%;
	z-index:10;
	text-align:center;
	margin:0 auto;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
} 
.hero-top p,
.hero-top h2{
	font-family: "Poppins";
} 
.hero-top p{
	/* margin-bottom:24px; */
	
	line-height: normal;
}
.hero-top h2{
	text-transform:uppercase;
	font-weight:700;
	letter-spacing:20px;
	font-size: 34px;
	line-height:52px;
	margin-bottom:50px;
} 
.hero-top .link-top{
	width:160px;
	font-weight:400;
	font-size: 15px;
	line-height:22px;
	letter-spacing:3px;
	display:inline-block;
	color:#000;
	border:1px solid #000;
	padding-top:8px;
	padding-bottom:8px;
	text-align:center;
	margin:0 auto;
	z-index:10;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.hero-top .link-top:hover{
	color:#fff;
}
 
.scroll-to-top {
	position: fixed;
	background-color:#f1f1f1;
	background-image:url('../images/arrow-top-short.png');
	background-repeat:no-repeat;
	background-position:center center;
	background-size:15px 15px;
	cursor: pointer;
	bottom: 30px;
	right: 30px;
	width:50px;
	height: 50px;
	display: none;
	z-index: 9999;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.scroll-to-top:hover {
	background-image:url('../images/arrow-top-short-white.png');
}
 

/* #Video Background
================================================== */

.slider-container {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
    margin: 16px 0;
  }

  .slider {
    display: flex;
    overflow-x: hidden; /* Enable horizontal scrolling */
    scroll-behavior: smooth; /* Smooth scrolling */
    scrollbar-width: none; /* Hide the scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar for Internet Explorer/Edge */
    width: 100%; /* Take up the whole width */
  }

  .slide {
    flex: 0 0 calc(33.333% - 20px); /* Display three images at once with margins */

    margin: 16px; /* Margin between images */
  }
  @media only screen and (min-width: 500px) and (max-width: 1120px) {
    .slide {
      flex: 0 0 calc(50% - 20px); /* Display three images at once with margins */
    margin: 16px; /* Margin between images */
  }
}
@media only screen and (max-width: 500px) {
    .slide {
      flex: 0 0 calc(100% - 20px); /* Display three images at once with margins */
    margin: 16px; /* Margin between images */
  }
}

  .slide:last-child {
    margin-right: 0; /* Remove margin from the last slide */
  }

  .slide img {
    width: 100%;
    height: auto;
  }

  .arrow {
    cursor: pointer;
    padding: 10px;
    background-color: transparent;
    font-size: 50px;
    z-index: 1; /* Ensure arrows are above images */
  }

  .arrow-left {
    left: 10px;
  }

  .arrow-right {
    right: 10px;
  }

.poster_background_home { 
	position: absolute; 
	bottom: 0; 
	left: 0;
	top:0;
	right:0;
	background-size:cover;
	width: 100%;
	height: 100%;
	z-index: -2;
	overflow: hidden;
}


video#video_background_home { 
	position: absolute;
	top:50%;
	left:50%;
	min-width: 100%;
	width: auto;
	height: auto;
	z-index: -1;
	overflow: hidden;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.white-over-video{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:rgba(255,255,255,.5);
	z-index:1;
}
 
/* #Porfolio 
================================================== */

.section{
	position:relative;
	width:100%;
	overflow: hidden;
}
.full-height{
	height:110vh;
	overflow: hidden;
}
.fullwidth-height{
	height:76vh;
}
.padding-top-bottom{
	padding-top:120px;
	padding-bottom:120px;
}
.padding-top{
	padding-top:120px;
}
.padding-bottom{
	padding-bottom:120px;
}
.padding-top-bottom-small{
	padding-top:60px;
	padding-bottom:60px;
}
.padding-top-bottom-smaller{
	padding-top:40px;
	padding-bottom:40px;
}
.padding-top-small{
	padding-top:60px;
}
.padding-bottom-small{
	padding-bottom:60px;
}
.padding-top-bottom-smaller{
	padding-top:30px;
	padding-bottom:30px;
}
.padding-top-smaller{
	padding-top:30px;
}
.padding-bottom-smaller{
	padding-bottom:30px;
}
.white-background{
	background:#fff;
}
.yellow-background{
	background-color: #fbca1c !important;
}

.grey-background{
	background:#f5f5f5;
}
.black-background{
	background:#32323298;
}
.dark-background{
	background:#222222;
}

.header-text{
	position:relative;
	width:100%;
	margin:0 auto;
	text-align:center;
	padding-bottom:20px;
}
.header-text h2,
.header-text h3,
.header-text h4{
	width:100%;
	margin:0 auto;
	text-align:center;
	max-width:800px;
}
.header-text p{
	position:relative;
	font-family: 'Poppins';
	font-weight:600;
	font-size: 48px;
	/* text-transform:uppercase; */
	text-align:center;
	color:#565656;
	padding-bottom:20px;
	margin-bottom:20px;
}
.header-text.main-pages span{
	font-size: 32px;
	font-family: 'Poppins';
	font-weight:600;
	margin: 0px;
	text-decoration: none !important;
	color: white;
}
.header-text.main-pages h3{
	font-size: 16px;
	font-family: 'Poppins';
	font-weight:600;
	color: white;
}

.header-text.main-pages h4{
	font-size: 16px;
	font-family: 'Poppins';
	font-weight:600;
	color: #112348;
	line-height: normal;
	letter-spacing: normal;
	word-spacing: normal;
}
.header-text p:after{
	position:absolute;
	content:'';
	height:3px;
	background-color:transparent;
	width:30px;
	left:50%;
	margin-left:-15px;
	bottom:0;
	z-index:1;
}

.action-wrap{
	position:relative;
	width:100%;
	margin:0 auto;
	text-align:center;
}
.action-wrap p{
	position:relative;
	margin:0 auto;
	font-family: 'Poppins';
	font-size: 16px;
	line-height:27px;
	text-align:center;
	letter-spacing:4px;
	width:100%;
	/* max-width:600px; */
}
.action-wrap h4{
	text-align:center;
	color:#fff;
	margin-top:10px;
}
.action-wrap h6{
	text-align:center;
	color:#fff;
	margin-top:20px;
}

.like-our-proj{
	position:relative;
	width:100%;
}
.like-our-proj h5{
	text-align:center;
	color:#fff;
	margin-bottom:10px;
}
.like-our-proj a{
	width:160px;
	margin:0 auto;
	display:block;
}
.like-our-proj span{
	position:relative;
	width:160px;
	margin:0 auto;
	letter-spacing:2px;
	font-size:15px;
	line-height:28px;
	text-align:center;
	font-weight:700;
	z-index:3;
	display:block;
}







*, *::after, *::before {
  box-sizing: border-box;
}


/* -------------------------------- 

Patterns - reusable parts of our design

-------------------------------- */
.overflow-hidden {
  overflow: hidden;
}


/* -------------------------------- 

Main content

-------------------------------- */
.cd-main {
  overflow-x: hidden;
}
.cd-main::before {
  /* never visible - this is used in jQuery to check the current MQ */
  display: none;
  content: 'mobile';
}
.cd-main > * {
  -webkit-transition: -webkit-transform 0.5s 0.4s;
  -moz-transition: -moz-transform 0.5s 0.4s;
  transition: transform 0.5s 0.4s;
}
.cd-main.fold-is-open > * {
  /* on mobile - translate .cd-main content to the right when the .cd-folding-panel is open */
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-transform 0.5s 0s;
  -moz-transition: -moz-transform 0.5s 0s;
  transition: transform 0.5s 0s;
}
@media only screen and (min-width: 600px) {
  .cd-main.fold-is-open > * {
    -webkit-transform: translateX(600px);
    -moz-transform: translateX(600px);
    -ms-transform: translateX(600px);
    -o-transform: translateX(600px);
    transform: translateX(600px);
  }
}
@media only screen and (min-width: 1100px) {
  .cd-main::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'desktop';
  }
  .cd-main.fold-is-open > * {
    /* reset style - on bigger devices we translate the gallery items */
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

.cd-gallery::after {
  clear: both;
  content: "";
  display: table;
}
.cd-gallery::before {
  /* this is the dark layer covering the .cd-gallery when the .cd-folding-panel is open */
  display: block;
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(28, 23, 38, 0.6);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s 0.4s, visibility 0s 0.9s;
  -moz-transition: opacity 0.5s 0.4s, visibility 0s 0.9s;
  transition: opacity 0.5s 0.4s, visibility 0s 0.9s;
}
.fold-is-open .cd-gallery::before {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.5s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.5s 0s, visibility 0s 0s;
  transition: opacity 0.5s 0s, visibility 0s 0s;
}

.cd-item {
  width: 100%;
  text-align: center;
  /* Force Hardware Acceleration */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.5s 0.4s;
  -moz-transition: -moz-transform 0.5s 0.4s;
  transition: transform 0.5s 0.4s;
}
.cd-item > a {
  display: table;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cd-item .portfolio-box {
	position:relative;
	width:100%;
	overflow:hidden;
}
.cd-item .portfolio-box img{
	width:100%;
	height:auto;
	display:block;
}
.cd-item .portfolio-box .mask {
	position:absolute;
	width:calc(100% - 40px);
	height:calc(100% - 40px);
	top:20px;
	left:20px;
	z-index:1;
	background:rgba(33,33,33,.9);
	opacity:0;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
}
.cd-item:hover .portfolio-box .mask {
	opacity:1;
}


.cd-item .portfolio-box .line {
	position:absolute;
	width:40px;
	height:3px;
	top:50%;
	left:50%;
	margin-left:-20px;
	margin-top:-14px;
	z-index:5;
	opacity:0;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.cd-item .portfolio-box:hover .line {
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
	opacity:1;
}

.cd-item .portfolio-box p {
	position:absolute;
	width:100%;
	top:50%;
	left:0;
	margin-top:-54px;
	z-index:5;
	opacity:0;
	font-family: 'Poppins';
	font-weight:700;
	font-size: 15px;
	text-transform:uppercase;
	text-align:center;
	color:#f1f1f1;
	letter-spacing:20px;
    -webkit-transform: translateY(-150px);
    -moz-transform: translateY(-150px);
    -ms-transform: translateY(-150px);
    -o-transform: translateY(-150px);
    transform: translateY(-150px);
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.cd-item .portfolio-box:hover p {
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
	opacity:1;
	letter-spacing:4px;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

.cd-item .portfolio-box h5 {
	position:absolute;
	width:100%;
	top:50%;
	left:0;
	margin-top:6px;
	z-index:5;
	opacity:0;
	text-align:center;
	color:#fff;
	letter-spacing:20px;
    -webkit-transform: translateY(150px);
    -moz-transform: translateY(150px);
    -ms-transform: translateY(150px);
    -o-transform: translateY(150px);
    transform: translateY(150px);
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.cd-item .portfolio-box:hover h5 {
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
	opacity:1;
	letter-spacing:4px;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}





@media only screen and (min-width: 1100px) {
  .cd-item {
    width: 50%;
    float: left;
    -webkit-transition: -webkit-transform 0.5s 0.4s;
    -moz-transition: -moz-transform 0.5s 0.4s;
    transition: transform 0.5s 0.4s;
  }
  .fold-is-open .cd-item {
    -webkit-transition: -webkit-transform 0.5s 0s;
    -moz-transition: -moz-transform 0.5s 0s;
    transition: transform 0.5s 0s;
    -webkit-transform: translateX(-400px);
    -moz-transform: translateX(-400px);
    -ms-transform: translateX(-400px);
    -o-transform: translateX(-400px);
    transform: translateX(-400px);
  }
  .fold-is-open .cd-item:nth-of-type(2n) {
    -webkit-transform: translateX(400px);
    -moz-transform: translateX(400px);
    -ms-transform: translateX(400px);
    -o-transform: translateX(400px);
    transform: translateX(400px);
  }
}

/* -------------------------------- 

folding panel

-------------------------------- */
.cd-folding-panel {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  visibility: hidden;
  overflow: hidden;
  -webkit-transition: visibility 0s 0.9s;
  -moz-transition: visibility 0s 0.9s;
  transition: visibility 0s 0.9s;
}
.cd-folding-panel .fold-left,
.cd-folding-panel .fold-right {
  /* the :after elements of .fold-left and .fold-right are the 2 fold sides */
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  /* enable a 3D-space for children elements */
  -webkit-perspective: 2000px;
  -moz-perspective: 2000px;
  perspective: 2000px;
}
.cd-folding-panel .fold-left::after,
.cd-folding-panel .fold-right::after {
  /* 2 fold sides */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* Force Hardware Acceleration */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.5s 0.4s, background-color 0.5s 0.4s;
  -moz-transition: -moz-transform 0.5s 0.4s, background-color 0.5s 0.4s;
  transition: transform 0.5s 0.4s, background-color 0.5s 0.4s;
}
.cd-folding-panel .fold-right {
  -webkit-perspective-origin: 0% 50%;
  -moz-perspective-origin: 0% 50%;
  perspective-origin: 0% 50%;
}
.cd-folding-panel .fold-right::after {
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -ms-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center;
  -webkit-transform: translateX(-100%) rotateY(-90deg);
  -moz-transform: translateX(-100%) rotateY(-90deg);
  -ms-transform: translateX(-100%) rotateY(-90deg);
  -o-transform: translateX(-100%) rotateY(-90deg);
  transform: translateX(-100%) rotateY(-90deg);
  background-color: #c0c3c3;
}
.cd-folding-panel .fold-left {
  /* on mobile only the right fold side is visible */
  display: none;
}
.cd-folding-panel .fold-left::after {
  background-color: #f9fafa;
}
.cd-folding-panel .cd-close {
  /* 'X' close icon */
  position: absolute;
  z-index: 1;
  display: inline-block;
  top: 10px;
  right: 10px;
  height: 44px;
  width: 44px;
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  opacity: 0;
  -webkit-transition: opacity 0.2s 0s;
  -moz-transition: opacity 0.2s 0s;
  transition: opacity 0.2s 0s;
}
@media only screen and (min-width: 1100px) {
.cd-folding-panel .cd-close {
  top: 30px;
  right: 43px;
}
}
.cd-folding-panel .cd-close::after, .cd-folding-panel .cd-close::before {
  /* lines of 'X' icon */
  content: '';
  position: absolute;
  height: 1px;
  width: 45px;
  left: 50%;
  top: 50%;
  background-color: #212121;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.cd-folding-panel .cd-close::after {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.cd-folding-panel .cd-close::before {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}
.cd-folding-panel.is-open {
  visibility: visible;
  -webkit-transition: visibility 0s 0s;
  -moz-transition: visibility 0s 0s;
  transition: visibility 0s 0s;
}
.cd-folding-panel.is-open .fold-right::after,
.cd-folding-panel.is-open .fold-left::after {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 0.5s 0s, background-color 0.5s 0s;
  -moz-transition: -moz-transform 0.5s 0s, background-color 0.5s 0s;
  transition: transform 0.5s 0s, background-color 0.5s 0s;
}
.cd-folding-panel.is-open .fold-right::after {
  background-color: #f9fafa;
}
.cd-folding-panel.is-open .cd-close {
  opacity: 1;
  -webkit-transition: opacity 0.2s 0.5s;
  -moz-transition: opacity 0.2s 0.5s;
  transition: opacity 0.2s 0.5s;
}
@media only screen and (min-width: 600px) {
  .cd-folding-panel {
    width: 100%;
  }
}
@media only screen and (min-width: 1100px) {
  .cd-folding-panel {
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
  }
  .cd-folding-panel .fold-left,
  .cd-folding-panel .fold-right {
    width: 50%;
    float: left;
    height: 100%;
  }
  .cd-folding-panel .fold-right {
    /* change perspective-origin so that the 2 fold sides have the same vanishing point */
    -webkit-perspective-origin: 0% 50%;
    -moz-perspective-origin: 0% 50%;
    perspective-origin: 0% 50%;
  }
  .cd-folding-panel .fold-right::after {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: translateX(-100%) rotateY(-90deg);
    -moz-transform: translateX(-100%) rotateY(-90deg);
    -ms-transform: translateX(-100%) rotateY(-90deg);
    -o-transform: translateX(-100%) rotateY(-90deg);
    transform: translateX(-100%) rotateY(-90deg);
  }
  .cd-folding-panel .fold-left {
    display: block;
    /* change perspective-origin so that the 2 fold sides have the same vanishing point */
    -webkit-perspective-origin: 100% 50%;
    -moz-perspective-origin: 100% 50%;
    perspective-origin: 100% 50%;
  }
  .cd-folding-panel .fold-left::after {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: translateX(100%) rotateY(90deg);
    -moz-transform: translateX(100%) rotateY(90deg);
    -ms-transform: translateX(100%) rotateY(90deg);
    -o-transform: translateX(100%) rotateY(90deg);
    transform: translateX(100%) rotateY(90deg);
  }
}
@media only screen and (min-width: 1600px) {
  .cd-folding-panel {
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 88%;
  }
}

.cd-fold-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  -webkit-transition: visibility 0s 0.4s;
  -moz-transition: visibility 0s 0.4s;
  transition: visibility 0s 0.4s;
}
.cd-fold-content > * {
  opacity: 0;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
}
.cd-fold-content .container {
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s 0s, opacity 0.3s 0s;
  -moz-transition: -moz-transform 0.3s 0s, opacity 0.3s 0s;
  transition: transform 0.3s 0s, opacity 0.3s 0s;
}

.is-open .cd-fold-content .container {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.3s 0.8s, opacity 0.3s 0.8s;
  -moz-transition: -moz-transform 0.3s 0.8s, opacity 0.3s 0.8s;
  transition: transform 0.3s 0.8s, opacity 0.3s 0.8s;
}
.is-open .cd-fold-content {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  visibility: visible;
  -webkit-transition: visibility 0s 0.5s;
  -moz-transition: visibility 0s 0.5s;
  transition: visibility 0s 0.5s;
}
.is-open .cd-fold-content > * {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
@media only screen and (min-width: 1100px) {
  .cd-fold-content {
  }
  .cd-fold-content > * {
    -webkit-transform: translateY(80px);
    -moz-transform: translateY(80px);
    -ms-transform: translateY(80px);
    -o-transform: translateY(80px);
    transform: translateY(80px);
  }
}

/* -------------------------------- 

Javascript disabled

-------------------------------- */
.no-js .cd-fold-content.single-page {
  position: static;
  visibility: visible;
  height: auto;
  background-color: #dadcdc;
}
.no-js .cd-fold-content.single-page > * {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}


.img-wrap{
	position: relative;
	width:100%;
	overflow: hidden;
}
.img-wrap img{
	width:100%;
	display:block;
}
.project-list{
	position: relative;
	width:100%;
	overflow: hidden;
	padding-top:40px;
}
.project-list p{
	text-align:left;
	font-weight:400;
	letter-spacing:2px;
	margin-top:10px;
}
.project-list p span{
	font-weight:700;
	text-transform:uppercase;
}

.section-project-text{
	position:relative;
	width:100%;
	margin:0 auto;
	
}
.section-project-text p{
	text-align:center;
	width:100%;
	max-width:850px;
	margin:0 auto;
}
.btn-projects {
	cursor: pointer;
	height:45px;
	width:150px;
	margin:0 auto;
	text-align:center;
	display: block;
	margin-top: 30px;
	color:#fff;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 12px;
	line-height:45px;
	outline: none;
	position: relative;
	overflow:hidden;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.btn-projects:hover {
	background:#212121;
	color:#fff;
}

/* Video Hover Image */	

.video-wrapper-box{
	position: relative;
	width:100%;
	overflow: hidden;
}
iframe {
	width: 100%;
}	
.video-wrapper, figure.vimeo, figure.youtube {
	margin:0;
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 0;
	height: 0;
	overflow: hidden;
}	
.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}	
figure.youtube a img, figure.vimeo a img {
	position: absolute;
	top: 0;
	left: 0;
	width: auto;
	height: 100%;
	max-width:none;
}	
figure.vimeo a:after, figure.youtube a:after {
	content:"";
	width:60px;
	height:60px;
	background:#fff;
	z-index:9;
	position:absolute;
	top:50%;
	left:50%;
	margin:-30px 0 0 -30px;
	border-radius:50%;
	-webkit-box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.1);
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
figure.vimeo:hover a:after, figure.youtube:hover a:after {
	background:#f9f9f9;
	-webkit-box-shadow: 0px 0px 30px 10px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 0px 30px 10px rgba(0,0,0,0.2);
	box-shadow: 0px 0px 30px 10px rgba(0,0,0,0.2);
}	
figure.vimeo a:before, figure.youtube a:before {
	content:"";
	width: 0;
	height: 0;
	border-top: 9px solid transparent;
	border-bottom: 9px solid transparent;
	border-left: 10px solid #414141;
	z-index:10;
	position:absolute;
	top:50%;
	left:50%;
	margin-left:-3px;
	margin-top:-7px;
	display:block;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
figure.vimeo:hover a:before, figure.youtube:hover a:before {
	border-left: 8px solid #000;
}	
figure.vimeo a:hover img, figure.youtube a:hover img {
	-webkit-transition: all 0.6s;
	transition: all 0.6s;
	-webkit-transform: scale(1.07) rotate(1deg);
	transform: scale(1.07) rotate(1deg);
}	
figure.vimeo a img, figure.youtube a img {
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
	-webkit-transform: scale(1);
	transform: scale(1);
}

 /* #About
================================================== */

.top-link-pages{
	position:absolute;
	bottom:30px;
	left:50%;
	margin-left:-10px;
	height:40px;
	width:20px;
	z-index:20;
	cursor:pointer;
	background:url('../images/arrow-down.png')no-repeat center bottom;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.top-link-pages:hover{
	height:60px;
	bottom:40px;
}


.about-box{
	position:relative;
	width:100%;
	margin:0 auto;
	overflow:hidden;
	cursor:pointer;
}
.about-box .icon{
	position:relative;
	width:100%;
	margin:0 auto;
	text-align:center;
	font-family: 'Ionicons';
	font-size:50px;
	line-height:50px;
	margin-bottom:30px;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear; 
}
.about-box:hover .icon{
	opacity:0;
	-webkit-transform: translateY(-150%);
	-moz-transform: translateY(-150%);
	-ms-transform: translateY(-150%);
	-o-transform: translateY(-150%);
	transform: translateY(-150%);
}
.about-box h6{
	text-align:center;
	color:#fff;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear; 
}
.about-box:hover h6{
	opacity:0;
	-webkit-transform: translateY(-350%);
	-moz-transform: translateY(-350%);
	-ms-transform: translateY(-350%);
	-o-transform: translateY(-350%);
	transform: translateY(-350%);
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
}
.about-box p{
	position:absolute;
	top:150%;
	left:0;
	width:100%;
	text-align:center;
	color:#f8f8f8;
	z-index:1;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear; 
}
.about-box:hover p{
	top:50%;
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
}




.cd-single-item {
  position: relative;
  background: #ffffff; }

.cd-slider-wrapper {
  position: relative;
  z-index: 1;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  transition: width 0.4s;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: width; }
  @media only screen and (min-width: 1024px) {
    .cd-slider-wrapper {
      width: 50%; }
      .cd-slider-active .cd-slider-wrapper {
        width: 100%; } }

.cd-slider {
  position: relative;
  z-index: 1;
  overflow: hidden; }
  .cd-slider::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'mobile';
    display: none; }
  .cd-slider li {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s; }
    .cd-slider li img {
      display: block;
      width: 100%; }
    .cd-slider li.selected {
      position: relative;
      z-index: 2;
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0); }
    .cd-slider li.move-left {
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%); }
  @media only screen and (min-width: 1024px) {
    .cd-slider {
      cursor: pointer; }
      .cd-slider::before {
        /* never visible - this is used in jQuery to check the current MQ */
        content: 'desktop'; }
      .cd-slider::after {
        /* slider cover layer - to indicate the image is clickable */
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
	background: rgba(0,0,0,.5) url("../images/cd-icon-zoom.svg") no-repeat center center;
        /* size of the icon */
        background-size: 48px;
        opacity: 0;
        z-index: 4;
        -webkit-transition: opacity 0.2s;
        -moz-transition: opacity 0.2s;
        transition: opacity 0.2s; }
      .no-touch .cd-slider:hover::after {
        opacity: 1; }
      .cd-slider-active .cd-slider {
        cursor: auto; }
        .cd-slider-active .cd-slider::after {
          display: none; } }

@media only screen and (min-width: 1024px) {
  .cd-slider-navigation li, .cd-slider-pagination {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.4s 0s, visibility 0s 0.4s;
    -moz-transition: opacity 0.4s 0s, visibility 0s 0.4s;
    transition: opacity 0.4s 0s, visibility 0s 0.4s; }
    .cd-slider-active .cd-slider-navigation li, .cd-slider-active .cd-slider-pagination {
      opacity: 1;
      visibility: visible;
      -webkit-transition: opacity 0.4s 0.4s, visibility 0s 0.4s;
      -moz-transition: opacity 0.4s 0.4s, visibility 0s 0.4s;
      transition: opacity 0.4s 0.4s, visibility 0s 0.4s; } }
.cd-slider-navigation li {
  position: absolute;
  z-index: 2;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
  .cd-slider-navigation li:first-of-type {
    left: 10px; }
  .cd-slider-navigation li:last-of-type {
    right: 10px; }
	
	
	
  .cd-slider-navigation li a {
    display: block;
    width: 48px;
    height: 48px;
    background: url("../images/cd-icon-arrow.svg") no-repeat center center;
    -webkit-transition: opacity 0.2s 0s, visibility 0s 0s;
    -moz-transition: opacity 0.2s 0s, visibility 0s 0s;
    transition: opacity 0.2s 0s, visibility 0s 0s;
    /* image replacement */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap; }
    .cd-slider-navigation li a.inactive {
      opacity: 0;
      visibility: hidden;
      -webkit-transition: opacity 0.2s 0s, visibility 0s 0.2s;
      -moz-transition: opacity 0.2s 0s, visibility 0s 0.2s;
      transition: opacity 0.2s 0s, visibility 0s 0.2s; }
    .no-touch .cd-slider-navigation li a:hover {
      opacity: .7; }
  .cd-slider-navigation li:first-of-type a {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  @media only screen and (min-width: 1024px) {
    .cd-slider-navigation li:first-child {
      left: 30px; }
    .cd-slider-navigation li:last-child {
      right: 30px; } }

	  
	  
	  
.cd-slider-pagination {
  /* you won't see this element in the html but it will be created using jQuery */
  position: absolute;
  z-index: 2;
  bottom: 30px;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden; }
  .cd-slider-pagination:after {
    content: "";
    display: table;
    clear: both; }
  .touch .cd-slider-pagination {
    visibility: hidden; }
  .cd-slider-pagination li {
    display: inline-block;
    float: left;
    margin: 0 3px; }
    .cd-slider-pagination li.selected a {
      background: #323232; }
  .cd-slider-pagination a {
    display: block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    border: 1px solid #323232;
    /* fix a bug in IE9/10 - transparent anchor not clickable */
    background-color: rgba(32, 32, 32, 0);
    /* image replacement */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap; }

.cd-slider-wrapper .cd-close {
  display: none;
  position: absolute;
  z-index: 2;
  top: 30px;
  right: 30px;
  width: 48px;
  height: 48px;
  background: url("../images/cd-icon-close.svg") no-repeat center center;
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s 0s, visibility 0s 0.4s;
  -moz-transition: -moz-transform 0.3s 0s, visibility 0s 0.4s;
  transition: transform 0.3s 0s, visibility 0s 0.4s; }
  .cd-slider-active .cd-slider-wrapper .cd-close {
    visibility: visible;
    opacity: 1;
    -webkit-transition: -webkit-transform 0.3s 0s, visibility 0s 0s, opacity 0.4s 0.4s;
    -moz-transition: -moz-transform 0.3s 0s, visibility 0s 0s, opacity 0.4s 0.4s;
    transition: transform 0.3s 0s, visibility 0s 0s, opacity 0.4s 0.4s; }
  .no-touch .cd-slider-active .cd-slider-wrapper .cd-close:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2); }
  @media only screen and (min-width: 1024px) {
    .cd-slider-wrapper .cd-close {
      display: block; } }

.cd-item-info {
  padding: 50px 16px; }
  .cd-item-info h4, .cd-item-info p {
    max-width: 100%; 
	text-align:left;}
@media only screen and (min-width: 1024px) {
.cd-item-info {
    position: absolute;
    width: 50%;
    top: 50%;
    right: 0;
    padding: 0 32px 0 3%;
    margin: 0; 
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
} 
}


.cd-item-info .header-text {
	text-align:left;
	margin:0;
}
.cd-item-info .header-text h4{
	text-align:left;
	width:100%;
	margin:0;
}
.cd-item-info .header-text p{
	text-align:left;
}
.cd-item-info .header-text p:after{
	position:absolute;
	content:'';
	height:3px;
	background-color:transparent;
	width:30px;
	left:0;
	margin-left:0;
	bottom:0;
	z-index:1;
}
.cd-item-info .small-text {
	text-align:left;
	margin-top:25px;
	font-family: 'Poppins';
	font-weight:400;
	font-size: 15px;
	line-height:23px;
	letter-spacing:1px;
	color:#828282;
}

.cd-content p {
  width: 80%;
  max-width: 768px;
  margin: 0 auto;}



@media only screen and (min-width: 1024px) and (max-width: 1199px) {
.cd-item-info .small-text {
	display:none;
}
}


.counter-wrap{
	position:relative;
	width:100%;
	margin:0 auto;
	z-index:10;
}	
.counter-numb{
	position:relative;
	width:100%;
	margin:0 auto;
	text-align:center;
	font-family: 'Poppins';
	font-size: 34px;
	line-height:28px;
	letter-spacing:3px;
	color:#fff;
	padding-bottom:5px;
}	
.counter-numb em{
	font-style: normal;
}
.counter-wrap .icon-count{
	position:absolute;
	font-family: "Ionicons";
	font-size:120px;
	line-height:120px;
	text-align:center;
	width:100%;
	left:0;
	top:50%;
	margin-top:-60px;
	z-index:-1;
	color:rgba(255,255,255,.1);
}	
.counter-wrap p{
	text-align:center;
	padding-top:5px;
	padding-bottom:0;
	color:#fff;
	text-transform:uppercase;
	letter-spacing:2px;
}


.team-box{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.team-box img{
	width:100%;
	display:block;
	padding-bottom:16px;
}
.team-box h6{
	text-align:center;
	font-size: 20px;

}

.subtitle{
	text-align:center;
width: 70%;
font-size:1.1vw;
word-spacing: normal;
letter-spacing: normal;
line-height: normal;
}
.team-box p{
	text-align:center;
	color: #D69B29;
}

.team-box .social-team{ 
	position:absolute;
	z-index:10;
	text-align:center;
	margin:0 auto;
	display:block;
	width:100%;
	left:0;
	bottom:100px;
}
.team-box .list-social-team li {
    padding:0; 
    list-style: none;
	text-align:center; 
	width:30px;
	height:30px;
    display: inline-block;
	border-radius:50%;
	background:#fff;
	cursor:pointer;
	margin-left:4px;
	margin-right:4px;
	opacity:0;
	-webkit-transform: translateY(-30px);
	-moz-transform: translateY(-30px);
	-ms-transform: translateY(-30px);
	-o-transform: translateY(-30px);
	transform: translateY(-30px);
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.team-box:hover .list-social-team li {
	opacity:1;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
}
.team-box .list-social-team li:nth-of-type(1) {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}
.team-box .list-social-team li:nth-of-type(2) {
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
}
.team-box .list-social-team li:nth-of-type(3) {
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
}
.team-box .list-social-team li:nth-of-type(4) {
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
}
.team-box .list-social-team li:nth-of-type(5) {
    -webkit-transition-delay: .4s;
    transition-delay: .4s;
}
.team-box .list-social-team li:nth-of-type(6) {
    -webkit-transition-delay: .5s;
    transition-delay: .5s;
}
.team-box .list-social-team li.icon-team a {
	font-family: 'Poppins';
	font-size: 11px;
	line-height: 30px;
	width:30px;
	color:#000;
	opacity:1;
	margin:0;
	padding:0;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.team-box .list-social-team li:hover{
}
.team-box .list-social-team li:hover.icon-team a{
	color:#fff;
}








.services-box{
	position:relative;
	width:100%;
	margin:0 auto;
}
.services-box .icon{
	position:relative;
	width:100%;
	margin:0 auto;
	text-align:center;
	font-family: 'Poppins';
	font-size:35px;
	line-height:35px;
	margin-bottom:20px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.services-box:hover .icon{
	-webkit-animation-name: thumb;
	-webkit-animation-duration: 250ms;
	-webkit-transform-origin:50% 50%;
	-webkit-animation-iteration-count: 2;
	-webkit-animation-timing-function: linear;
	animation-name: thumb;
	animation-duration: 250ms;
	transform-origin:50% 50%;
	animation-iteration-count: 2;
	animation-timing-function: linear;
}
.services-box h6{
	text-align:center;
	margin-bottom:30px;
}
.services-box p{
	text-align:center;
}


@-webkit-keyframes thumb {
	0% { -webkit-transform: scale(1) ; }
	25% { -webkit-transform: scale(0.8) translateX(-5px) rotate(10deg); }
	50% { -webkit-transform: scale(1) translateX(5px) rotate(-10deg); }
	75% { -webkit-transform: scale(0.8) translateX(-5px) rotate(10deg); }
	100% { -webkit-transform: scale(1); }
}
@keyframes thumb {
	0% { transform: scale(1); }
	25% { transform: scale(0.8) translateX(-5px) rotate(10deg); }
	50% { transform: scale(1) translateX(5px) rotate(-10deg); }
	75% { transform: scale(0.8) translateX(-5px) rotate(10deg); }
	100% { transform: scale(1); }
}


#owl-logo{
	position:relative;
	width:100%;
	margin:0 auto;
	text-align:center;
}
#owl-logo .item{
	position:relative;
	width:100%;
	margin:0 auto;
	text-align:center;
}
#owl-logo .item img{
	width:80px;
	display:block;
	margin:0 auto;
	text-align:center;
}



.quote-wrap-hart{
	position:relative;
	width:100%;
	margin:0 auto;	
}
.quote-wrap-hart .icon{
	position:relative;
	width:100%;
	margin:0 auto;
	text-align:center;
	font-family: 'Ionicons';
	font-size:45px;
	line-height:45px;
	margin-bottom:40px; 
	-webkit-animation: hart 0.8s ease infinite;
	animation: hart 0.8s ease infinite;
}
@-webkit-keyframes hart {
	0% { -webkit-transform: scale(1) ; }
	25% { -webkit-transform: scale(0.9); }
	50% { -webkit-transform: scale(0.8) }
	75% { -webkit-transform: scale(0.9)}
	100% { -webkit-transform: scale(1); }
}
@keyframes hart {
	0% { transform: scale(1); }
	25% { transform: scale(0.9); }
	50% { transform: scale(0.8); }
	75% { transform: scale(0.9); }
	100% { transform: scale(1); }
}


#owl-quote {
	position:relative;
	width:100%;
	margin:0 auto;
	overflow:hidden;	
}
#owl-quote .item{
	position:relative;
	width:100%;
	margin:0 auto;
	background-size:cover;
	background-position:center center;
	background-repeat:no-repeat;
	z-index:2;
	overflow:hidden;
}
#owl-quote .item p{
	font-style: italic;
	color:#fff;
	font-size: 18px;
	line-height:28px;
	letter-spacing:3px;
	text-align:center;
	margin:0 auto;
	max-width:850px;
	width:100%;
}
#owl-quote .item h6{
	color:#fff;
	text-align:center;
	padding-top:30px;
	margin-bottom:50px;
}



#bar-quote{
	width: 0%;
	max-width: 100%;
	height: 2px;
}
#progressBar-quote{
	position:absolute;
	bottom:0;
	left:50%;
	width: 160px;
	margin-left:-80px;
	background: #f8f8f8;
	z-index:5;
}
.prev-quote{
	position:absolute;
	left:0;
	top:50%;
	margin-top:-10px;
	width:30px;
	height:20px;
	z-index:20;
	cursor:pointer;
	background:url('../images/arrow-left-white.png')no-repeat center left;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.next-quote{
	position:absolute;
	right:0;
	top:50%;
	margin-top:-10px;
	width:30px;
	height:20px;
	z-index:20;
	cursor:pointer;
	background:url('../images/arrow-right-white.png')no-repeat center right;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.prev-quote:hover,
.next-quote:hover{
	width:40px;
}
.prev-quote:hover{
	left:5px;
}
.next-quote:hover{
	right:5px;
}


 /* #Blog
================================================== */
 
#blog-grid{
	position:relative;
	text-align:center;
	margin:0 auto;
	width:calc(100% - 50px);
}
#blog-grid:after {
  content: '';
  display: block;
  clear: both;
}
.blog-box-1{
	position:relative;
	float:left;
	width:calc(25% - 50px);
	display:block;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin-top:25px;
	margin-right:24px;
	margin-bottom:25px;
	margin-left:25px;
	overflow:hidden;
	padding:30px;
	-webkit-transform: translate3d(1px,0,0);
	transform: translate3d(1px,0,0);
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.blog-box-1.home-blog{
	width:100%;
	margin-top:0;
	margin-right:0;
	margin-bottom:0;
	margin-left:0;
	padding:30px;
}
.blog-box-1 .date{
	position:relative;
}
.blog-box-1 .date p{
	font-family: 'Poppins';
	font-weight:400;
	font-size: 14px;
	line-height:23px;
	letter-spacing:2px;
	text-align:left;
	color:#fff;
}
.blog-box-1 .date p span{
	font-size: 42px;
	line-height:42px;
}
.blog-box-1 .date p em{
	margin-top:19px;
	float:right;
	display: block;
}
.blog-box-1 h6{
	position:relative;
	text-align:left;
	line-height:30px;
	margin-top:30px;
	padding-bottom:10px;
	color:#f8f8f8;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.blog-box-1 h6:hover{
	opacity:.7;
}
.blog-box-1.quote h6:hover{
	opacity:1;
}
.blog-box-1 .p-box{
	position:relative;
	width:100%;
	display:block;
	margin-bottom:30px;
	padding-top:20px;
}
.blog-box-1 .p-box p{
	text-align:left;
	color:#f8f8f8;
}
.blog-box-1 .p-box:after {
  content: '';
  display: block;
  clear: both;
}
.blog-box-1 img{
	width:100%;
	height:auto;
	display:block;
	margin-top:30px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.blog-box-1 img:hover{
}

.blog-box-1 a{
	display:block;
}
a.blog-link{
	position:relative;
	width:130px;
	letter-spacing:2px;
	font-size:15px;
	line-height:23px;
	text-align:left;
	font-weight:700;
	z-index:3;
	display:block;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
a.blog-link:hover{
	color:#fff;
}
.news-link a.blog-link{
	margin:0 auto;
	text-align:center;
}
a.blog-link:after{
	font-family: 'FontAwesome';
	padding-left:15px;
	font-weight:normal;
	content: "\f178";
}

#owl-blog {
	position:relative;
	width:100%;
	margin:0 auto;
	overflow:hidden;
}
#owl-blog .item{
	position:relative;
	width:100%;
	margin:0 auto;
	z-index:2;
	overflow:hidden;
}
#owl-blog .item img{
	width:100%;
	display:block;
}

/* Video Hover Image */	

.blog-box-1.video img{
	margin-top:0;
}
.blog-box-1.video .date{
	margin-bottom:30px;
}


.video-wrapper, figure.vimeo, figure.youtube {
	margin:0;
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 0;
	height: 0;
	overflow: hidden;
}	
.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}	
figure.youtube a img, figure.vimeo a img {
	position: absolute;
	top: 0;
	left: 0;
	width: auto;
	height: 100%;
	max-width:none;
}	
figure.vimeo a:after, figure.youtube a:after {
	content:"";
	width:60px;
	height:60px;
	background:#fff;
	z-index:9;
	position:absolute;
	top:50%;
	left:50%;
	margin:-30px 0 0 -30px;
	border-radius:50%;
	-webkit-box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.1);
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
figure.vimeo:hover a:after, figure.youtube:hover a:after {
	background:#f9f9f9;
	-webkit-box-shadow: 0px 0px 30px 10px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 0px 30px 10px rgba(0,0,0,0.2);
	box-shadow: 0px 0px 30px 10px rgba(0,0,0,0.2);
}	
figure.vimeo a:before, figure.youtube a:before {
	content:"";
	width: 0;
	height: 0;
	border-top: 9px solid transparent;
	border-bottom: 9px solid transparent;
	border-left: 10px solid #414141;
	z-index:10;
	position:absolute;
	top:50%;
	left:50%;
	margin-left:-3px;
	margin-top:-7px;
	display:block;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
figure.vimeo:hover a:before, figure.youtube:hover a:before {
	border-left: 8px solid #000;
}	
figure.vimeo a:hover img, figure.youtube a:hover img {
	-webkit-transition: all 0.6s;
	transition: all 0.6s;
	-webkit-transform: scale(1.07) rotate(1deg);
	transform: scale(1.07) rotate(1deg);
}	
figure.vimeo a img, figure.youtube a img {
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
	-webkit-transform: scale(1);
	transform: scale(1);
}


#blog-filter {
	position:relative;
	width:100%;
	text-align: center;
}
#filter {
	position:relative;
	width:100%;
	text-align: center;
}

#filter li {
	display: inline-block;
	text-align: center;
}
#filter:after {
  content: '';
  display: block;
  clear: both;
}
#filter li a {
	position: relative;
	display: inline-block;
	letter-spacing:3px;
	font-family: 'Poppins';
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 16px; 
	margin-bottom:5px;
	text-align: center;
	outline: none;
	color: #646464;
	margin-left:10px;
	margin-right:10px;
	text-decoration: none;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
#filter li .current {
}
#filter li a:hover {
}
.isotope-item {
    z-index: 2;
}
.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}
.isotope, .isotope .isotope-item {
    -webkit-transition-duration: 0.3s;
       -moz-transition-duration: 0.3s;
            transition-duration: 0.3s;
}
.isotope {
    -webkit-transition-property: height, width;
       -moz-transition-property: height, width;
            transition-property: height, width;
}
.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
       -moz-transition-property:    -moz-transform, opacity;
        -ms-transition-property:     -ms-transform, opacity;
         -o-transition-property:         top, left, opacity;
            transition-property:         transform, opacity;
}
  

 /* #Blog Post
================================================== */

.post-block {
	position:relative;
	width:100%;
	overflow:hidden;
	margin-top:50px;
}
.post-block:after {
  content: '';
  display: block;
  clear: both;
}
.post-block p{
	text-align:left;
	margin-bottom:20px;
}

.tags-block {
	position:relative;
	width:100%;
	overflow:hidden;
	margin-top:50px;
	margin-bottom:50px;
	padding:30px;
}
.tags-block a{
	text-transform:uppercase;
	letter-spacing:2px;
	padding:3px 20px;
	background:#000;
	font-size:12px;
	color:#fff;
	display:inline-block;
	margin-right:5px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.tags-block a.autor-link{
	float:right;
}

.post-block input,
.post-block textarea{
	margin-bottom:20px;;
}





.post-sidebar{ 
	position:relative;
	width:100%;
}

.post-sidebar input{
	width:100%;
	padding-left:20px;
	padding-right:20px;
	font-family: 'Poppins';
	font-weight:400;
	letter-spacing:1px;
	font-size: 12px;
	line-height:22px;
	padding-top: 15px;
	padding-bottom: 15px;
	background:#f1f1f1;
	border:none;
	color: #737373;
	-webkit-transition: background 0.3s, color 0.3s;
	transition: background 0.3s, color 0.3s;
}
.post-sidebar input:active {	
	color: #fff;
	outline: none !important;
}
.post-sidebar input:hover {	
	color: #737373;
	outline: none !important;
}
.post-sidebar input:focus {
	color: #fff;	 
	outline: none !important;
}

.post-sidebar input:-ms-input-placeholder  {
	font-size: 10px;
	text-transform:uppercase;
	-webkit-transition:  color 0.3s;
	transition:  color 0.3s;
}
.post-sidebar input::-moz-placeholder  {
	font-size: 10px;
	text-transform:uppercase;
	-webkit-transition:  color 0.3s;
	transition:  color 0.3s;
}
.post-sidebar input:-moz-placeholder  {
	font-size: 10px;
	text-transform:uppercase;
	-webkit-transition:  color 0.3s;
	transition:  color 0.3s;
}
.post-sidebar input::-webkit-input-placeholder  {
	font-size: 10px;
	text-transform:uppercase;
	-webkit-transition:  color 0.3s;
	transition:  color 0.3s;
}
.post-sidebar input:active:-ms-input-placeholder  {
	color: #fff;
}
.post-sidebar input:active::-moz-placeholder  {
	color: #fff;
}
.post-sidebar input:active:-moz-placeholder  {
	color: #fff;
}
.post-sidebar input:active::-webkit-input-placeholder  {
	color: #fff;
}
.post-sidebar input:focus:-ms-input-placeholder  {
	color: #fff;
}
.post-sidebar input:focus::-moz-placeholder  {
	color: #fff;
}
.post-sidebar input:focus:-moz-placeholder  {
	color: #fff;
}
.post-sidebar input:focus::-webkit-input-placeholder  {
	color: #fff;
}



.post-sidebar h6{ 
	text-align:left;
	padding-bottom:30px;
}
.link-tag{ 
	position:relative;
	width:100%;
	overflow:hidden;
	text-align:left;
}
.link-tag a {
	text-transform:uppercase;
	letter-spacing:2px;
	padding:3px 15px;
	background:#000;
	font-size:10px;
	color:#fff;
	display:inline-block;
	margin-right:5px;
	margin-bottom:5px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.link-tag a:hover {
}
.link-recents{ 
	position:relative;
	width:100%;
	overflow:hidden;
	text-align:left;
}
.link-recents a{
	position:relative;
	display:block;
	cursor:pointer;
	font-size: 11px;
	line-height:21px;
	text-align:left;
	text-transform:uppercase;
	padding-top:10px;
	padding-bottom:10px;
	border-top:1px solid rgba(0,0,0,.1);
	letter-spacing:1px;
	color:#323232;
	font-weight:300;
	-webkit-transition: padding-left 0.3s, color 0.3s;
	transition: padding-left 0.3s, color 0.3s;
}
.link-recents a:last-child{
	border-bottom:1px solid rgba(0,0,0,.1);
}
.link-recents a:hover {
	padding-left:10px;
}
.lat-pro{ 
	position:relative;
	width:100%;
}
.lat-pro:after {
  content: '';
  display: block;
  clear: both;
}
.lat-pro-img{
	position:relative; 
	width:33.3333333333333333%;
	float:left;
	display:inline-block;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.lat-pro-img img{ 
	width:100%;
	display:block;
	height:auto;
}
.lat-pro-img:hover{ 
	opacity:0.4;
}
.separator-sidebar{ 
	position:relative;
	width:100%;
	padding-top:70px;
}





 /* #Contact
================================================== */

#google-container {
	position: relative;
	width: 100%;
    height:400px;
}
#cd-google-map {
	position: relative;
}
#cd-google-map address {
	position: absolute;
	bottom: 50px;
	left: 50px;
	border-radius:3px;
	padding:30px;
	background-color: rgba(255, 255, 255, 0.9);
	font-family: 'Poppins';
	font-weight:400;
	font-size: 15px;
	line-height:23px;
	letter-spacing:1px;
	color:#747474;
	text-align: left;
}

#cd-zoom-in, #cd-zoom-out {
	height: 32px;
	width: 32px;
	cursor: pointer;
	margin-left: 10px;
	background-repeat: no-repeat;
	background-size: 32px 64px;
	opacity:.7;
	background-image: url("../images/cd-icon-controller.svg");
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.no-touch #cd-zoom-in:hover, .no-touch #cd-zoom-out:hover {
	opacity:1;
}
#cd-zoom-in:hover, #cd-zoom-out:hover {
	opacity:1;
}
@media only screen and (min-width: 768px) {

  #cd-zoom-in, #cd-zoom-out {
    margin-left: 50px;
  }
}
#cd-zoom-in {
	background-position: 50% 0;
	margin-top: 50px;
	margin-bottom: 1px;
}
#cd-zoom-out {
	background-position: 50% -32px;
}



#ajax-form {
	width: 100%;
	font-family: 'Poppins';
	font-size: 10px;
	line-height:14px;
	color:#101010;
	margin:0 auto;
}
#ajax-form label {
	display: block;
	font-family: "Poppins";
	font-weight: 600;
	font-size: 14px;
	line-height:30px;
}
#ajax-form input{
	width:30vw;
	padding-left:20px;
	padding-right:20px;
	margin: 64px 32px;
	font-family: 'Poppins';
	font-weight:400;
	letter-spacing:1px;
	font-size: 13px;
	line-height:24px;
	padding-bottom: 15px;
	background:transparent;
	border:none;
	color: #737373;
	-webkit-transition: border-bottom 0.3s, color 0.3s;
	transition: border-bottom 0.3s, color 0.3s;
}

.contactus-button{
	background-color: white !important;
	color: #D69B29 !important;
    border: 2px solid #D69B29 !important; /* Adding border property with color */
	border-radius: 4px !important;
	width: 107px !important;
	height: 55px !important;
	margin-bottom: 48px !important;
	letter-spacing: unset !important;
}

.contactus-button:hover{
	background-color: #D69B29 !important;
	color: white !important;
	border: 2px solid #D69B29 !important; /* Adding border property with color */
	border-color: white !important;
	border-radius: 4px !important;
	width: 107px !important;
	height: 55px !important;
	letter-spacing: unset !important;

}

.parallax-footer{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	width: 100%;
	background-color: #112348;
}


.parallax-3{
	background: url(https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/backgrounds/AM_Homepage_Store%20BG.png) repeat fixed;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
}

#ajax-form textarea {
	width:calc(60vw + 52px);
	padding-left:20px;
	padding-right:20px;
	margin-bottom: 64px;
	font-family: 'Poppins';
	font-weight:400;
	letter-spacing:1px;
	font-size: 13px;
	line-height:24px;
	padding-bottom: 15px;
	background:transparent;
	height:100px;
	border:none;
	color: #737373;
	-webkit-transition: border-bottom 0.3s, color 0.3s;
	transition: border-bottom 0.3s, color 0.3s;
}
#ajax-form textarea,
#ajax-form input {	
	border-bottom:1px solid rgba(100,100,100,.2);
}
.error {
	font: 11px/22px 'Open Sans', sans-serif;
	text-transform:uppercase;
	letter-spacing:1px;
	display: none;
	color:#636363;
}
#ajaxsuccess {
	background: #f2f2f2;
	color:#101010;
	font: 20px/60px 'Open Sans', sans-serif;
	height:60px;
	display: none;
	padding-left:10px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
}
.text-align-center{
	text-align:center;
	padding-top:10px;
 }
#ajax-form textarea:active,
#ajax-form input:active {	
	color: #101010;
}
#ajax-form textarea:active,
#ajax-form input:hover {	
	color: #101010;
}
#ajax-form textarea:focus,
#ajax-form input:focus {	 
    outline: none !important;
}
#ajax-form input:-ms-input-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color:#999999;
}
#ajax-form input::-moz-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color:#999999;
}
#ajax-form input:-moz-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color:#999999;
}
#ajax-form input::-webkit-input-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color:#999999;
}
#ajax-form textarea:-ms-input-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color:#999999;
}
#ajax-form textarea::-moz-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color:#999999;
}
#ajax-form textarea:-moz-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color:#999999;
}
#ajax-form textarea::-webkit-input-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color:#999999;
}
#ajax-form button {
	position:relative;
	width:160px;
	height:40px;
	font-family: 'Poppins';
	font-weight:400;
	letter-spacing:2px;
	text-align:center;
	border-radius:3px;
	margin:0 auto;
	display: block;
	padding:0;
	font-size: 15px;
	line-height:40px;
	background:#efefef;
	border:none;
	-webkit-transition: background 0.3s, color 0.3s;
	transition: background 0.3s, color 0.3s;
}
#ajax-form button:hover {
	color:#fff;
}
#ajax-form button:focus,
#ajax-form button:active {
	border:none;
	outline:none;
}
#ajax-form button{
}
#ajax-form textarea:focus,
#ajax-form input:focus,
#ajax-form textarea:active,
#ajax-form input:active {	
}


.contact-block{
	position: relative;
	width:100%;
	margin:0 auto;
}
.contact-block .text{
	position:relative;
	width:100%;
}
.contact-block .text h6{
	position:relative;
	text-align:center;
	padding-bottom:30px;
	color:#fff;
}
.contact-block .text p span{
	font-family: "Ionicons";
	padding-right:5px;
	font-size: 18px; 
	font-weight:normal;
}
.contact-block .text p{
	position:relative;
	text-align:center;
	color:#fff;
}



 /* #Features
================================================== */

.blocks-wrapper{
	position:relative;
	width:100%;
	padding-top:130px;
	padding-bottom:130px;
}
.block{
	position:relative;
	width:100%;
	background:#000;
	padding-top:20px;
	padding-bottom:20px;
	text-align:center;
	text-transform:uppercase;
	letter-spacing:2px;
	font-size:11px;
	font-weight:400;
	color:#fff;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}


h4.features {
	text-align:center;
	position:relative;
	padding-bottom:20px;
	margin-bottom:10px;
}
h4.features:after{
	position:absolute;
	content:'';
	height:3px;
	background-color:transparent;
	width:30px;
	left:50%;
	margin-left:-15px;
	bottom:0;
	z-index:1;
} 



.pricing-item{
	position:relative;
	width:100%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding:40px;
	margin:0 auto;
	text-align:center;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
	border-top:10px solid transparent;
}
.pricing-item h5{
	text-align:center;
	padding-bottom:20px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.pricing-item p{
	text-align:center;
	padding-top:3px;
	padding-bottom:3px;
    text-transform: uppercase;
	letter-spacing:2px;
	font-size: 13px;
}
.pricing-item p span{
	font-weight:700;
}
.pricing-item .number-price{
	position:relative;
	width:100%;
	font-family: 'Poppins';
	font-weight:300;
	font-size: 54px;
	line-height:54px;
	color:#323232;
	text-align:center;
	padding-bottom:17px;
}
.pricing-item .number-price span{
	font-family: 'FontAwesome';
	font-size: 16px;
	line-height:62px;
	vertical-align:super;
	font-weight:400;
	padding-right:8px;
}
.pricing-item .number-price span:last-child{
	font-family: 'Poppins';
	font-size: 16px;
	line-height:54px;
	vertical-align:-1%;
	font-weight:400;
	padding-right:0;
	padding-left:5px;
}
.pricing-item a.price-link{
	position:relative;
	font-family: 'Poppins';
	text-align:center;
	padding-top:7px;
	padding-bottom:7px;
	width:130px;
	margin:0 auto;
	margin-top:25px;
	letter-spacing:2px;
	display:block;
	border-radius:3px;
	font-size: 15px;
	color:#fff;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.pricing-item a.price-link:hover{
	background:#111;
} 


p.justify{
	text-align:justify;
}
.gu-box{
	position:relative;
	width:100%;
	padding-bottom:20px;
}
.gu-box.float-quotes{
	float:right;
	max-width:400px;
	margin-left:30px;
}
.gu-box p{
	color:#000;
	padding-left:30px;
	padding-top:20px;
	padding-bottom:20px;
	letter-spacing:2px;
	font-style: italic;
	font-size: 16px;
	line-height:28px;
}
.gu-box span{
	color:#999999;
	padding-left:30px;
	font-weight:600;
	letter-spacing:3px;
	font-size: 12px;
	line-height:20px;
}
.gu-box.margin-gu{
	margin-top:20px;
	margin-bottom:20px;
}


.alert{
	position: relative; 
	width: 100%;
	overflow:hidden;
	padding:20px;
}
.alert p{
	font-weight:400;
	font-size: 12px;
	line-height:18px;
	color:#212121;
	text-transform: uppercase;
	letter-spacing:1px;
} 
.alert p span{
	font-size: 12px;
	font-family: 'Poppins';
	padding-right:15px;
}  
.alert.alert-green{
	background-color:#c5ebd4;
	border-left:3px solid #8cd7aa;
} 
.alert.alert-red{
	background-color:#ffcccb;
	border-left:3px solid #ff817f;
} 
.alert.alert-blue{
	background-color:#b9dff4;
	border-left:3px solid #77c1ea;
}   
.alert.alert-yelow{
	background-color:#fce1b6;
	border-left:3px solid #f9c36d;
}    
.alert.alert-blank{
	background-color:#eeeeee;
	border-left:3px solid #ccc;
}   
.alert.alert-blank-2{
	background-color:#333333;
	border-left:3px solid #111;
}  
.alert.alert-blank-2 p{
	color:#fff;
}


.pro-bar-container {
	position:relative;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	width: 100%;
	height: 10px;
	background: #ccc;
}
.pro-bar-margin {
	margin-bottom:30px;
}
.skills-name{
	position:relative;
	width:100%;
	text-align:left;
	padding-bottom:5px;
	font-weight:400;
	font-size: 12px;
	letter-spacing:2px;
	color:#999999;
	text-transform: uppercase;
}
.skills-name span{
	font-weight:700;
	color:#121212;
	padding-left:5px;
}
.pro-bar {
	position:relative;
	height: 10px;
	overflow:hidden;
}
	.pro-bar.bar-100	{ width: 100%; }
	.pro-bar.bar-99		{ width: 99%;  }
	.pro-bar.bar-98		{ width: 98%;  }
	.pro-bar.bar-97		{ width: 97%;  }
	.pro-bar.bar-96		{ width: 96%;  }
	.pro-bar.bar-95		{ width: 95%;  }
	.pro-bar.bar-94		{ width: 94%;  }
	.pro-bar.bar-93		{ width: 93%;  }
	.pro-bar.bar-92		{ width: 92%;  }
	.pro-bar.bar-91		{ width: 91%;  }
	.pro-bar.bar-90		{ width: 90%;  }
	.pro-bar.bar-89		{ width: 89%;  }
	.pro-bar.bar-88		{ width: 88%;  }
	.pro-bar.bar-87		{ width: 87%;  }
	.pro-bar.bar-86		{ width: 86%;  }
	.pro-bar.bar-85		{ width: 85%;  }
	.pro-bar.bar-84		{ width: 84%;  }
	.pro-bar.bar-83		{ width: 83%;  }
	.pro-bar.bar-82		{ width: 82%;  }
	.pro-bar.bar-81		{ width: 81%;  }
	.pro-bar.bar-80		{ width: 80%;  }
	.pro-bar.bar-79		{ width: 79%;  }
	.pro-bar.bar-78		{ width: 78%;  }
	.pro-bar.bar-77		{ width: 77%;  }
	.pro-bar.bar-76		{ width: 76%;  }
	.pro-bar.bar-75		{ width: 75%;  }
	.pro-bar.bar-74		{ width: 74%;  }
	.pro-bar.bar-73		{ width: 73%;  }
	.pro-bar.bar-72		{ width: 72%;  }
	.pro-bar.bar-71		{ width: 71%;  }
	.pro-bar.bar-70		{ width: 70%;  }
	.pro-bar.bar-69		{ width: 69%;  }
	.pro-bar.bar-68		{ width: 68%;  }
	.pro-bar.bar-67		{ width: 67%;  }
	.pro-bar.bar-66		{ width: 66%;  }
	.pro-bar.bar-65		{ width: 65%;  }
	.pro-bar.bar-64		{ width: 64%;  }
	.pro-bar.bar-63		{ width: 63%;  }
	.pro-bar.bar-62		{ width: 62%;  }
	.pro-bar.bar-61		{ width: 61%;  }
	.pro-bar.bar-60		{ width: 60%;  }
	.pro-bar.bar-59		{ width: 59%;  }
	.pro-bar.bar-58		{ width: 58%;  }
	.pro-bar.bar-57		{ width: 57%;  }
	.pro-bar.bar-56		{ width: 56%;  }
	.pro-bar.bar-55		{ width: 55%;  }
	.pro-bar.bar-54		{ width: 54%;  }
	.pro-bar.bar-53		{ width: 53%;  }
	.pro-bar.bar-52		{ width: 52%;  }
	.pro-bar.bar-51		{ width: 51%;  }
	.pro-bar.bar-50		{ width: 50%;  }
	.pro-bar.bar-49		{ width: 49%;  }
	.pro-bar.bar-48		{ width: 48%;  }
	.pro-bar.bar-47		{ width: 47%;  }
	.pro-bar.bar-46		{ width: 46%;  }
	.pro-bar.bar-45		{ width: 45%;  }
	.pro-bar.bar-44		{ width: 44%;  }
	.pro-bar.bar-43		{ width: 43%;  }
	.pro-bar.bar-42		{ width: 42%;  }
	.pro-bar.bar-41		{ width: 41%;  }
	.pro-bar.bar-40		{ width: 40%;  }
	.pro-bar.bar-39		{ width: 39%;  }
	.pro-bar.bar-38		{ width: 38%;  }
	.pro-bar.bar-37		{ width: 37%;  }
	.pro-bar.bar-36		{ width: 36%;  }
	.pro-bar.bar-35		{ width: 35%;  }
	.pro-bar.bar-34		{ width: 34%;  }
	.pro-bar.bar-33		{ width: 33%;  }
	.pro-bar.bar-32		{ width: 32%;  }
	.pro-bar.bar-31		{ width: 31%;  }
	.pro-bar.bar-30		{ width: 30%;  }
	.pro-bar.bar-29		{ width: 29%;  }
	.pro-bar.bar-28		{ width: 28%;  }
	.pro-bar.bar-27		{ width: 27%;  }
	.pro-bar.bar-26		{ width: 26%;  }
	.pro-bar.bar-25		{ width: 25%;  }
	.pro-bar.bar-24		{ width: 24%;  }
	.pro-bar.bar-23		{ width: 23%;  }
	.pro-bar.bar-22		{ width: 22%;  }
	.pro-bar.bar-21		{ width: 21%;  }
	.pro-bar.bar-20		{ width: 20%;  }
	.pro-bar.bar-19		{ width: 19%;  }
	.pro-bar.bar-18		{ width: 18%;  }
	.pro-bar.bar-17		{ width: 17%;  }
	.pro-bar.bar-16		{ width: 16%;  }
	.pro-bar.bar-15		{ width: 15%;  }
	.pro-bar.bar-14		{ width: 14%;  }
	.pro-bar.bar-13		{ width: 13%;  }
	.pro-bar.bar-12		{ width: 12%;  }
	.pro-bar.bar-11		{ width: 11%;  }
	.pro-bar.bar-10		{ width: 10%;  }
	.pro-bar.bar-9		{ width: 9%;   }
	.pro-bar.bar-8		{ width: 8%;   }
	.pro-bar.bar-7		{ width: 7%;   }
	.pro-bar.bar-6		{ width: 6%;   }
	.pro-bar.bar-5		{ width: 5%;   }
	.pro-bar.bar-4		{ width: 4%;   }
	.pro-bar.bar-3		{ width: 3%;   }
	.pro-bar.bar-2		{ width: 2%;   }
	.pro-bar.bar-1		{ width: 1%;   }
	.pro-bar.bar-0		{ width: 0%;   }


	
.smk_accordion {
	position: relative;
	margin: 0;
	padding: 0;
	list-style: none;
}
.smk_accordion .accordion_in {
	position: relative;
	z-index: 10;
	margin-top: -1px;
	overflow: hidden;
	margin-bottom:4px;
}
.smk_accordion .accordion_in .acc_head {
	position: relative;
	padding: 15px;
	text-align:left;
	background:#fff;
	font-weight:600;
	font-size: 13px;
	line-height:20px;
	text-transform: uppercase;
	color: #666666;
	letter-spacing:2px;
	display: block;
	cursor: pointer;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.smk_accordion .accordion_in .acc_head:hover {
	color:#fff;
}
.smk_accordion .accordion_in.acc_active > .acc_head {
	color:#fff;
}
.smk_accordion .accordion_in .acc_head .acc_icon_expand {
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	right: 20px;
	top: 50%;
	margin-top: -10px;
	background: url('../images/acc1.png')no-repeat center center;
	background-size:20px 20px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.smk_accordion .accordion_in .acc_head:hover .acc_icon_expand {
	background: url('../images/acc2.png')no-repeat center center;
	background-size:20px 20px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.smk_accordion .accordion_in.acc_active > .acc_head .acc_icon_expand {
	background: url('../images/acc3.png')no-repeat center center;
	background-size:20px 20px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}
.smk_accordion .accordion_in .acc_content {
	color:#606060;
	padding:30px;
	background:#fff;
}
.smk_accordion .accordion_in .acc_content h1:first-of-type,
.smk_accordion .accordion_in .acc_content h2:first-of-type,
.smk_accordion .accordion_in .acc_content h3:first-of-type,
.smk_accordion .accordion_in .acc_content h4:first-of-type,
.smk_accordion .accordion_in .acc_content h5:first-of-type,
.smk_accordion .accordion_in .acc_content h6:first-of-type {
  margin-top: 5px;
}
.smk_accordion .accordion_in:first-of-type,
.smk_accordion .accordion_in:first-of-type .acc_head {
}
.smk_accordion .accordion_in:last-of-type,
.smk_accordion .accordion_in:last-of-type .acc_content {
}
.smk_accordion .accordion_in.acc_active > .acc_content {
  display: block;
}
.smk_accordion.acc_with_icon .accordion_in .acc_head,
.smk_accordion.acc_with_icon .accordion_in .acc_content {
  padding-left: 40px;
}	
	

.whyaquaman{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100vw;
	height: fit-content;
	margin-top: 32px;
}

.whyaquaman-right{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	margin: 0 32px;
	height: 360px;
}

.whyaquaman-left{
	height: 360px;
}

.whyaquaman-left-image{
	width: 50vw; /* Make the image take up the full width of its container */
    height: 100%; /* Make the image take up the full height of its container */
    object-fit: cover; /* Ensure the image covers */
}




 /* #Footer
================================================== */

.shabab{
	background-image: url("../images/shabab.svg");
	background-size: 100px 100px;
	height: 100px;
	width: 100px;
}

.social-footer-mobiles{
	display: none;
}

.footer-flex{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 80px;
	height: 30vh;
	font-family: "Poppins";
}

.footer-column{
	display: flex;
	flex-direction: column;
	text-align: left;
	justify-content: flex-start;
	margin: 0 32px;
}

.footer-column-title{
	margin: 20px 0;
	color: #D69B29;
	font-weight: 700;
	font-size: 14px;
	text-align: left;
}

.footer-column-items{
	color: white;
	font-size: 16px;
	font-weight: 500;
	text-align: left;
}


 .footer-img img{
	
	background-image: url("../images/logo.png");
} 


.social-footer{ 	
	width: 300px;
}

.insta{
	background-image: url("../images/instagram.svg");
	width: 32px;
	height: 32px;
	background-size: 32px 32px;
}
.youtube{
	background-image: url("../images/youtube.svg");
	width: 32px;
	height: 32px;
	background-size: 32px 32px;
}
.tiktok{
	background-image: url("../images/tiktok.svg");
	width: 32px;
	height: 32px;
	background-size: 32px 32px;
}
.facebook{
	background-image: url("../images/facebook.svg");
	width: 32px;
	height: 32px;
	background-size: 32px 32px;
}
.mail{
	background-image: url("../images/mail.svg");
	width: 34px;
	height: 29px;
	background-size: 32px 32px;
	
}

.list-social li {
    padding:0; 
    list-style: none;
	text-align:center; 
	width:30px;
	height:30px;
    display: inline-block;
	border-radius:50%;
	background:#000;
	cursor:pointer;
	margin-left:4px;
	margin-right:4px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}

.list-social li.icon-soc a {
	font-family: 'Poppins';
	font-size: 11px;
	line-height: 30px;
	width:30px;
	color:#fff;
	opacity:1;
	margin:0;
	padding:0;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; 
}
.list-social li:hover{
}
.list-social li:hover.icon-soc a{
}

.footer-copy-text{
	position: relative;
	width:100%;
	margin:48px auto;
	text-align:center;
}
.footer-copy-text p{
	font-size: 16px;
	text-align:center;
	font-weight: 600;
	color: white;
}
.footer-copy-text p span{
	font-size: 16px;
	letter-spacing:4px;
	text-transform:uppercase;
}


.mobile{
	display: none;
	font-family: "Poppins";
}

 
/* #Media Queries
================================================== */

@media only screen and (max-width: 1450px) {
.blog-box-1{
	width:calc(33.33333% - 50px);
}
}

@media only screen and (max-width: 1300px) {
.prev-quote{
	display:none;
}
.next-quote{
	display:none;
}

.footer{
	height: fit-content;
}


.footer-flex{
	display: flex;
	align-items: start;
	justify-content: space-between;
	flex-direction: column;
	padding: 0 80px;
	height: max-content;
}


}

@media only screen and (max-width: 1100px) {
.blog-box-1{
	width:calc(50% - 50px);
}

}


@media only screen and (max-width: 1200px) {
.logo{
	height:58px;
	background:url('../images/mainlogo.svg') no-repeat center center;
	background-size:120px 58px;
}

.appbar-left{
	width: 100%;
	padding: unset;
}

.main-button{
	display: none;
}

.mobile{
	display: unset;
	
	background-color: transparent;
	color: white;
	font-family: "Poppins";
	border: solid 1px white;
}
.cbp-af-header.cbp-af-header-shrink .logo{
	width:200px;
	height:58px;
	background:url('../images/mainlogo.svg') no-repeat center center;
	background-size:120px 58px;
	-webkit-transition : all 0.5s ease-out;
	-moz-transition : all 0.5s ease-out;
	-o-transition :all 0.5s ease-out;
	transition : all 0.5s ease-out;
}
.cbp-af-header.cbp-af-header-shrink ul.slimmenu li a {
    border-bottom:none;
}
.cbp-af-header.cbp-af-header-shrink ul.slimmenu li a:hover {
    border-bottom:none;
	background:none;
}
.cbp-af-header.cbp-af-header-shrink ul.slimmenu li {
	background:none;
}
ul.slimmenu li a {
    border-bottom:none;
}
ul.slimmenu li a:hover {
    border:none;
	background:none;
}
ul.slimmenu li {
	background:none;
}
ul.slimmenu li > ul {
    width: 100%;
	text-align:right;
}
}


@media only screen and (max-width: 800px) {
.blog-box-1{
	width:calc(100% - 50px);
}
.flex-column{
	flex-direction: column;
  }

  .whyaquaman-left{
	display: none;
}

.slimmenu{
	display: none;
}

.whyaquaman{
	margin-bottom: 32px;
}



}

/* Burger Menu Overrides */
  
.bm-cross-button {
	height: 50px; /* Increase size of the close button */
	width: 50px; /* Increase size of the close button */
	margin-top: 20px; /* Optional: Add space on top */
	margin-right: 20px; /* Optional: Add space on the right */
  }
  
  .bm-cross {
	background: #fff; /* Make the "X" white */
	height: 20px; /* Increase thickness of the "X" lines */
	width: 50px; /* Extend the "X" lines */
	border-radius: 4px; /* Make the	 "X" lines slightly rounded for better visuals */
  }
  
#react-burger-cross-btn{
width: 30px;
height: 30px;
content: "X";
  }


.bm-burger-button {
	position: fixed;
	width: 26px; /* Adjust button width */
	height: 20px; /* Adjust button height */
	right: 20px;
	top: 24px;
  }
  
  .bm-burger-bars {
	background: #fff; /* White burger bars */
  }
  
  .bm-menu {
	background: #112348; /* Dark blue background for the menu */
	padding: 2.5em 1.5em 0;
	font-size: 1.5em; /* Adjust menu font size */
  }

  
  .bm-item {
	display: block; /* Ensure each item is on a new line */
	color: white; /* White text */
	text-decoration: none; /* Remove underline */
	margin-bottom: 2.5em; /* Increase spacing between items */
	line-height: 3em; /* Increase line height for additional spacing */
	font-size: 1.2em; /* Make the text size large */
	font-weight: 700;
	text-align: left; /* Align text to the left */
	margin-top: 2em;
  }

  .bm-item li {
	height: 1.5em;
  }
  
  .bm-item a {
	line-height: 0.8em; /* Increase line height for additional spacing */
	color: white;
  }

  .bm-item a:hover,
.bm-item a:focus {
  color: orange; /* Change to orange on hover and focus */
}

  .bm-item:hover {
	color: #000000; 
  }
  
  .bm-overlay {
	background: rgba(0, 0, 0, 0.3); /* Dim background overlay */
  }
  

@media only screen and (max-width: 600px) {
	.flex-column{
		flex-direction: column;
	  }
}

@media only screen and (min-width: 1200px) and (max-width: 1319px) {
.hero-top h2{
	letter-spacing:14px;
	font-size: 26px;
	line-height:34px;
} 
.width-inherit{
width: inherit;
}
}

@media only screen and (min-width: 960px) and (max-width: 1199px) {
.hero-top h2{
	letter-spacing:12px;
	font-size: 24px;
	line-height:32px;
}
.container-top{
	width: 100vw;
	/* padding: 0 64px; */
}

.flex-column{
	flex-direction: column;
  }

}

@media only screen and (min-width: 768px) and (max-width: 959px) {
	.flex-column{
		flex-direction: column;
	  }
.hero-top h2{
	letter-spacing:8px;
	font-size: 20px;
	line-height:28px;
}
.container-top{
	width: 100vw;
	padding: 0 64px;

}

}

@media only screen and (min-width: 480px) and (max-width: 767px) {
.hero-top h2{
	letter-spacing:1px;
	font-size: 18px;
	line-height:26px;
}
.container-top{
	width: 100vw;
	/* padding: 0 64px; */

}


}

@media only screen and (min-width: 250px) and (max-width: 479px) {
.hero-top h2{
	letter-spacing:1px;
	font-size: 18px;
	line-height:26px;
}
.container-top{
	width: 100vw;
	/* padding: 0 44px; */

}

.footer-logo-full{
	display: none;
}

.social-footer-mobiles{
	display: unset;
	margin: 48px;
}


}

@media only screen and (max-width: 319px) {
.hero-top h2{
	letter-spacing:1px;
	font-size: 18px;
	line-height:26px;
}
.container-top{
	width: 100vw;
	/* padding: 0 44px; */

}
}

.width-inherit{
	width: none;
	}















