.payment-container {
  text-align: center;
  padding: 20px;
}

  
  .payment-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
  }
  
  .payment-header h2 {
    color: #333;
    font-size: 20px;
    margin-top: 1em;
  }
  
  .payment-details {
    background: #fff;
    padding: 15px;
    margin-top: 20px;
    border: 1px solid #ccc;
  }
  
  .payment-details p {
    font-size: 16px;
    color: #666;
  }
  
  .notice, .contact-info {
    font-size: 14px;
    color: #333;
    margin-top: 4px;
  }
 
  .overlay-text h1 {
    font-family: "Syncopate";
    font-size: 28px;
    color: white;
  }

  .overlay-text p {
    font-family: "Poppins";
    font-size: 24px;
    color: white;
  }

  .payment-header h2 {
    color: #28AD12;
    font-size: 20px
  }

  .main-date {
    position: relative;
    padding: 34px 24px;
    font-weight: bold;
    overflow: unset;
    white-space: unset;
    top: 12.4vh;
    font-size: 18px;
    background-color: #D69B29;
    box-sizing: border-box;
  }
  
  @media only screen and (min-width: 768px) {

    .main-date {
      position: relative;
      padding: 34px 24px;
      font-weight: bold;
      overflow: unset;
      white-space: unset;
      top: 11.6vh;
      font-size: 18px;
      background-color: #D69B29;
      box-sizing: border-box;
    }

    .overlay-text h1 {
      font-family: "Syncopate";
      font-size: 60px;
      color: white;
    }

    .overlay-text p {
      font-family: "Poppins";
      font-size: 24px;
      color: white;
    }

    .payment-header h2 {
      color: #28AD12;
      font-size: 24px
    }
  }

  .successImg{
    width: 100px;
  }

  .gallery-container {
    width: 70vw;
    padding: 0 32px;
    padding-right: 32px; // Added padding on the right
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .galleryTitle{
    text-align: center;
     margin-bottom: 6px;
      margin-top: 20px;
      font-size: 30px;
    color:#112348 
  }


   .locButton {
    margin-left: auto;
    margin-right: auto;
      padding: 10px 20px;
      font-size: 16px;
      width: 200px;
      cursor: pointer;
      background-color: #D69B29;
      color: white;
      border: none;
      border-radius: 5px;
    }

  .gallerySubTitle{
    text-align: center;
    margin-bottom: 20px;
     margin-top: 6px;
   color:#112348 ;
   font-weight: 400;
    font-size: 14px;
     line-height: 1.6
  }

  .galleryExtraTitle{
    text-align: center;
    margin-bottom: 10px;
     margin-top: 10px;
   color:#112348 ;
    font-size: 14px;
     line-height: 1.6
  }

  .gallery-img{
    width: 600px; // Smaller image width
    height: 400px; // Smaller image height
    object-fit: cover; // Ensures the image scales well
    cursor: pointer;
  }

  @media (max-width: 767px) {
    .gallery-container {
      width: 90vw;
      padding: 0 16px;
      padding-right: 16px; // Added padding on the right
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .galleryTitle{
        font-size: 5vw;
    }

    .gallerySubTitle{
      font-size: 10px;
       line-height: 1.5;
       margin-top: 0px;
    }
  
    .galleryExtraTitle{
      font-size: 10px;
       line-height: 1.5
    }

    .gallery-img{
      width: 320px; // Smaller image width
      height: 200px; // Smaller image height
      object-fit: cover; // Ensures the image scales well
      cursor: pointer;
    }
  }  