

.makanak-body{
      margin: 0;
    padding: 0;
    background-color: #033275; /* Sets the whole page background color */
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh; /* Ensures content is vertically centered */
}
  
  .MainContainer {
    text-align: center;
  }
  
  .containerMakanak {
    padding: 20px; /* Initial padding */
    width: 95%; /* Set initial max-width as a percentage for better responsiveness */
    background-color: white; /* White background for the form container */
    color: #033275; /* Text inside the container will be dark */
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    transition: all 0.3s ease; /* Smooth transition when resizing */
    margin: auto;
  }
  
  .logot {
    width: 250px; /* Default size */
    margin-bottom: 24px;
    margin-top: 1.2em;
    max-width: 100%; /* Ensures the logo scales down if the screen is smaller */
  }
  
  /* For large screens like tablets and desktops */
  @media (min-width: 768px) {
    .containerMakanak {
      max-width: 600px; /* On larger screens, the container will be wider */
      padding: 40px; /* More padding on larger screens */
    }
  
    .explanatory-text p {
      font-size: 18px; /* Larger font size for text on larger screens */
    }
  
    .labelMakanak {
      font-size: 16px;
    }
  
    .inputMakanak {
      padding: 12px;
      font-size: 16px;
    }
  
    .buttonMakanak {
      font-size: 16px;
      padding: 12px;
    }
  }
  
  /* For small phones (less than 600px width) */
  @media (max-width: 600px) {
    .containerMakanak {
      max-width: 90%; /* On small phones, use 90% width */
      padding: 20px; /* Less padding on smaller screens */
    }
  
    .explanatory-text p {
      font-size: 14px; /* Slightly smaller text on small phones */
    }
  
    .labelMakanak {
      font-size: 14px; /* Reduce font size for labels on small screens */
    }
  
    .inputMakanak {
      padding: 10px;
      font-size: 14px;
    }
  
    .buttonMakanak {
      font-size: 14px;
      padding: 10px;
    }
  
    .logot {
      margin-top: 2em;
      width: 220px; /* Adjust logo size for small screens */
    }
  }
  
  /* For extra small phones (less than 400px width) */
  @media (max-width: 400px) {
    .containerMakanak {
      padding: 15px;
      width: 100%; /* Use full width for very small screens */
    }
  
    .explanatory-text p {
      font-size: 12px; /* Smaller font size for extra small screens */
    }
  
    .labelMakanak {
      font-size: 12px;
    }
  
    .inputMakanak {
      padding: 8px;
      font-size: 12px;
    }
  
    .buttonMakanak {
      font-size: 12px;
      padding: 8px;
    }
  
    .logot {
      margin-top: 2em;
      width: 200px; /* Further adjust logo size */
    }
  }
  
  .explanatory-text {
    text-align: start; /* Aligns all content inside to the start (left) */
    margin-bottom: 20px;
  }
  
  .explanatory-text h6 {
    margin-bottom: 5px; /* Optional: adjust spacing between titles and paragraphs */
    text-align: start; /* Aligns all content inside to the start (left) */
  }
  
  .explanatory-text p {
    margin-bottom: 15px; /* Optional: adds spacing between paragraphs */
    line-height: 1.5;
  }
  
  .explanatory-text div {
    opacity: 0; /* Initially hidden */
    transform: translateY(20px); /* Start slightly below */
    animation: fadeInUp 1s forwards; /* Animate upwards and fade in */
  }
  
  /* Animation timing for each section */
  .explanatory-text div:nth-child(1) {
    animation-delay: 0s; /* First section appears after 0.5s */
  }
  
  .explanatory-text div:nth-child(2) {
    animation-delay: 0.6s; /* Second section appears after 1s */
  }
  
  .explanatory-text div:nth-child(3) {
    animation-delay: 1.2s; /* Third section appears after 1.5s */
  }
  
  .explanatory-text div:nth-child(4) {
    animation-delay: 2s; /* Fourth section appears after 2s */
  }
  
  /* Keyframes for fade and upward movement */
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px); /* Start from below */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at normal position */
    }
  }
  
  .formMakanak {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .labelMakanak {
    margin-bottom: 15px;
    width: 100%;
    text-align: left;
  }
  
  .inputMakanak {
    padding: 10px;
    margin-top: 5px;
    width: 100%;
    background-color: #f0f0f0;
    color: #033275;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .buttonMakanak {
    padding: 10px;
    background-color: #033275;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
    border-radius: 5px;
  }
  
  .buttonMakanak:hover {
    background-color: #033275;
  }
  
  .survey h5 {
    color:#033275
  }

  .survey-row {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .survey-row p {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .survey-options {
    display: flex;
    justify-content: flex-end;
    width: 70%;
    margin-top: 1em;
  }
  
  .inputMakanak[type="radio"] {
    appearance: none; /* Remove default radio button styling */
    width: 16px;
    height: 16px;
    border: 2px solid #033275; /* Default border color */
    border-radius: 50%;
    margin: 0;
    padding: 0;
    position: relative;
    cursor: pointer;
  }
  
  .inputMakanak[type="radio"]:checked {
    background-color: #033275; /* Yellow color when selected */
    border-color: #033275; /* Border color changes to yellow */
  }
  
  .inputMakanak[type="radio"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: #033275;
    border-radius: 50%;
    display: none;
  }
  
  .inputMakanak[type="radio"]:checked::before {
    display: block; /* Show inner dot when checked */
  }
  
  
  .parallax-footer,
  .footer-flex,
  .social-footer-mobiles,
  .footer-copy-text,
  .footer {
  }
  
  .social-footer-mobiles-makanak {
    margin-top: 5em;
  }
  
  .footer-copy-text-makanak {
    position: relative;
    width: 100%;
    margin: 48px auto;
    text-align: center;
  }
  
  .footer-copy-text-makanak p {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    color: white;
  }
  
  .footer-copy-text-makanak p span {
    font-size: 16px;
    letter-spacing: 4px;
    text-transform: uppercase;
  }
  
  .social-icon {
    background-size: contain;
    background-repeat: no-repeat;
    animation: blueHueChange 3s infinite ease-in-out; /* Continuous color change animation */
    transition: all 0.3s ease-in-out;
    filter: brightness(1); /* Initial brightness */
  }
  
  /* Uncomment if using color animation */
  // @keyframes blueHueChange {
  //   0% {
  //     filter: hue-rotate(190deg); /* Blue shade start */
  //   }
  //   50% {
  //     filter: hue-rotate(200deg); /* Slightly different blue */
  //   }
  //   100% {
  //     filter: hue-rotate(220deg); /* Another blue variation */
  //   }
  // }
  