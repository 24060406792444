.main-container{
    display: flex;
    flex-direction: column;
}

.carousel-container {
    position: relative;
    height: 30vh;
  }

  .carousel-main{
    width: 100%;
  max-width: 100%;
  height: 30vh;
  }

  // .react-multi-carousel-item{
  //   width: 100vw !important;
  //   height: 25vh !important;
  // }

  
  
  .carousel-text h3{
    font-size: 40px;
    line-height: 40px;
   font-family: 'Syncopate' !important;
    font-weight: bold;
    color: white;
  }

  @media only screen and (max-width: 500px) {
    .carousel-text h3{
      font-size: 28px;
      line-height: 24px;
    }
  }


  .carousel-text {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: bold;
    color: white;
  }

  .carousel-text p{
    font-size: 18px;
    line-height: 20px;
  }

  .carousel-date {
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
      font-size: 14px;
      padding: 18px 12px;
      bottom: -30px;
    font-weight:400;
    background-color: #D69B29;
    box-sizing: border-box;
color: white
  }

  .invalid {
    border-color: red !important;
  }


  @media only screen and (min-width: 768px) {
    .carousel-main{
      height: 60vh;
    }

    .react-multi-carousel-item{
      width: 100vw !important;
      height: 60vh !important;
    }

    .carousel-container {
      position: relative;
      height: unset;
    }
    .carousel-text p{
      font-family: 'Poppins';
      font-size: 26px;
    line-height: 30px;
    }
    .carousel-text h3{
      font-family: 'Syncopate' !important;
      font-size: 63px;
    line-height: 70px;
    }
    .carousel-date {
      font-family: 'Poppins';
      font-size: 24px;
      padding: 34px 24px;
      font-weight: bold;
      bottom: -45px;
      overflow: unset;
    white-space: unset;
    }
  }
  .weather-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFF; /* Choose the appropriate color */
    padding: 10px 40px;
  }
  
  .info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Add additional styling as needed */
  }
  
  .label {
    font-weight: bold;
    /* Add additional styling as needed */
  }
  
  .info{
    align-items: center;
    display: flex;
    flex-wrap: wrap; 
  }

  .floating-button {
    position: fixed;
    top: 20px; /* Adjust distance from bottom */
    right: 20px; /* Adjust distance from right */
    z-index: 1000;
  }

  .info-section{
    display: flex;
    flex-direction: row;
    flex: 0 0 50%; 
    padding: 6px;
  }

  
  
  .language-selector button, .registration button {
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    /* Add additional styling as needed */
  }

  .language-selector select {
    padding: 10px 24px;
    border: 1px solid #D69B29;
    border-radius: 5px;
    background-color: white;
    color: #D69B29;
    cursor: pointer;
  }
  
  /* Style the dropdown to expand its width to fit the content */
  .language-selector select {
    width: auto; /* Or set a specific width if you prefer */
  }

  .MobileCenterEvent{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .registration{
    border-radius: 5px;
    background-color: #D69B29;
    padding: 12px 12px;
    font-size: 16px;
    width: fit-content;
    color: white;
  }

  .icon{
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }
  .label{
    color: #112348;
    font-size: 8px;
    font-weight: 200;
  }

  .value{
    color: #112348;
    font-size: 12px;
    font-weight: 600;
  }

  .split-container {
    display: flex;
    align-items: start;
    padding: 0 64px 32px 64px;
  }


  @media only screen and (min-width: 768px) {
    .weather-info{
      padding: 10px 64px;

    }

    .info{
      display: flex;
      width: 50vw;
      justify-content: space-between;
    }

    .info-section{
     flex: none;
     padding: 0px;
    }

    .label{
      color: #112348;
      font-size: 10px;
      font-weight: 200;
    }
    .value{
      color: #112348;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .split-container-mobile{
    display: flex;
    flex-direction: column;
    padding: 0 32px 32px 32px;
    align-items: center;
  }
  
  .left-side {
    flex: 25%; 
    display: flex;
    flex-direction: column;
    padding: 32px 64px 0 0;

  }
  
  .right-side {
    flex: 75%; 
  }

  .text-with-arrow {
    position: relative;
    padding-bottom: 10px; 
    border-bottom: 1px solid #DBE7FF; 
    display: flex; 
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    color: #112348;
    font-size: 18px;
    font-weight: 800;
    cursor: pointer;
  }

  .clicked{
    color: #D69B29;
    cursor: pointer;

  }
  .arrowClicked{
       fill: #D69B29;
 }

  .text-with-arrow .arrow-right {
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent; 
    border-bottom: 5px solid transparent; 
    border-left: 5px solid #DBE7FF; 
  }

  .registration-Main{
    display: flex;
    flex-direction: column;
    padding: 80px 32px  ;
  }

  .formInput{
    width: -webkit-fill-available;
    height: 50px;
    margin: 0;
    margin-bottom: 26px;
    margin-bottom: 1em;
    border-color: #555;
    // color:  #112348;
    padding-right: 10px;
    &::placeholder {
      color: #112348 !important;
      padding-left: 6px;
    }
  }
  .select{
    border: solid 1px #555;
    padding-left: 6px;
    border-radius: 6px;
  }

  .SubmitBtn{
    width: 60%;
    border-radius: 5px;
    background-color: #D69B29;
    color: #FFF;
  }

  .mainRegistrationContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .registrationSubContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .SubmitBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px; /* Adjust as needed */
    width: 200px; /* Adjust as needed */
    position: relative;
  }
  
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: 100%;
  }
  

  @media only screen and (min-width: 768px) {
    .mainRegistrationContainer {
      width: unset;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    .SubmitBtn{
      width: 14em;
      border-radius: 5px;
      background-color: #D69B29;
      color: #FFF;
    }

    .registrationSubContainer{
      width: 45%;
                          display: flex;
                          flex-direction: column;
                          align-items: flex-start;
    }

    .registration-Main{
      display: flex;
      flex-direction: column;
      padding: 90px 64px  ;
    }
  
  }

  //////////////////////////// Previous Event ////////////////////////////
  
  /* General Container */
.prevEvent-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

/* Section Titles */
.prevEvent-title {
  font-size: 2rem;
  font-weight: bold;
  color: #0b1930;
}

.prevEvent-subtitle {
  font-size: 1rem;
  color: #c8a04d;
  margin-top: 5px;
}

/* Video Placeholder */
.prevEvent-video-placeholder {
  width: 80%;
  height: 250px;
  background-color: #d3d3d3;
  border: 2px solid #0044ff;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #0b1930;
}

/* Image Gallery */
.prevEvent-image-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 20px;
}

.prevEvent-gallery-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}



/* Responsive Design */
@media (max-width: 768px) {
  .prevEvent-image-gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}