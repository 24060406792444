.checkout-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

@media only screen and (max-width: 1200px) {
    .checkout-container{
        flex-direction: column;
        align-items: center;
    
    }

    .checkout-content-right{
        margin-left: 64px !important;
        width: 90vw !important;
        margin-left: unset !important;
        margin-right: unset !important;
    }
    .checkout-content-width{
        width: 90vw !important;
    }

    .checkout-content-left{
        width: 90vw !important;
        margin-left: unset !important;
        margin-right: unset !important;
    }

    .whitespace-dynamic{
        white-space: unset !important;
        margin: 0 32px;
    }

    .exp{
        white-space: nowrap;
    }

}


.checkout-content-left {
    padding: 32px;
    border: 1px solid #1123484D;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 64px;
    margin-right: 16px;
    margin-bottom: 32px;
    width: 45vw;
}

.checkout-content-right {
    padding: 32px;
    border: 1px solid #1123484D;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 64px;
    margin-left: 16px;
    margin-bottom: 32px;
    width: 45vw;
}

.checkout-content-right input {
    margin: unset;
}



.checkout-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    width: 100%;
}


.checkout-title-container p {
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #D69B29;
    margin: 0 4px;
}

.checkout-details-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 32px;
    width: 100%;
    margin-left: 60px;
}

.checkout-details-container p {
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #112348;
    margin: 2px;
}

.checkout-read {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
}
.checkout-input{
    border: 1px solid #D69B29;
    border-right: none;
    width: 70%;
    padding-left: 10px;
    border-radius: 6px 0 0 6px;
    ::placeholder{
        color: #D69B29;
    }
}

.checkout-promo-cont{
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
    margin-top: 2em;
}

.checkout-content-width {
    width: 40vw;
}


.CheckoutBtn{
    width: 100%;
    border-radius: 5px;
    background-color: #D69B29;
    color: #FFF;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  .CheckoutBtnDisable{
    background-color: #d69c299c;
  }


  /* Hide the default checkbox */
.custom-checkbox input[type="checkbox"] {
    display: none;
  }
  
  /* Create a custom checkmark */
  .custom-checkbox .custom-checkmark {
    width: 18px;
    height: 18px;
    border: 1px solid #1a223284;
    border-radius: 50%;
    display: inline-block;
    position: relative;
  }
  
  /* Change background color if checked */
  .custom-checkbox input[type="checkbox"]:checked + .custom-checkmark {
    background-color: #d69c299e;
  }
  


.whitespace-dynamic{
    white-space: nowrap;
}

.exp{
    white-space: wrap;
    max-width: 67%;

}